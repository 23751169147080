import { Link } from "react-router-dom";
import { FormEvent, useState, useRef } from "react";
import { Loader } from "../../components";
import { toast, ToastContainer, Flip } from "react-toastify";
import { AxiosApi } from "../../config";
import { AuthWrapper } from "../../layout";

export const AdminForgotPassword = () => {
  // the state for controlling the loading of the auth
  const [isLoading, setIsLoading] = useState(false);
  // could have used state but am avoiding uncesessary re-rendering
  const emailRef = useRef<HTMLInputElement>(null);

  // making the api call to let the admin reset password by sending a link
  const handleForgotPassword = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      if (validateFormData()) {
        setIsLoading(true);
        const { data } = await AxiosApi.post("/endpoint", {
          email: emailRef.current?.value,
        });

        setIsLoading(false);
        console.log(data);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  // could have used a form validation library but utilizing the amount of libraries available is important
  const validateFormData = () => {
    if (!emailRef.current?.value) {
      toast.error("Please enter your email");

      return false;
    } else return true;
  };

  return (
    <div>
      <AuthWrapper title="Forgot Password">
        <form
          className="bg-white rounded-md py-6 px-5 lg:px-10"
          onSubmit={handleForgotPassword}
        >
          <p className="text-consult-color text-sm text-center text-opacity-60 sm:w-[300px] lg:w-[350px] mb-4">
            we will send a reset link to your email please follow the
            instructions
          </p>
          <div className="mb-4 sm:w-[300px] lg:w-[350px]">
            <label
              htmlFor="email"
              className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
            >
              Email
            </label>

            <input
              type="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
              placeholder="Email Address"
              ref={emailRef}
            />
          </div>

          <div className="flex flex-col items-center gap-y-2 mt-5">
            <button className="tracking-widest whitespace-nowrap text-white bg-primary focus:ring-4 focus:outline-none focus:ring-green-300 font-semibold rounded text-sm px-5 w-fit lg:w-1/2 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              Send Reset Link
            </button>
            <Link
              to="/auth/login"
              className="text-blue-600 hover:underline dark:text-blue-500"
            >
              login
            </Link>
          </div>
        </form>
      </AuthWrapper>

      <Loader loading={isLoading} />

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Flip}
      />
    </div>
  );
};
