import { Outlet, useNavigate } from "react-router-dom";
import { AdminNavbar, AdminSidebar } from "../../layout";
import { BottomDrawer } from "../../components";
import { Fragment, useEffect } from "react";
import { useLocalStorage } from "../../hooks";

export const AdminRoot = () => {
  const navigateTo = useNavigate();
  const { getToken } = useLocalStorage();
  const token = getToken();

  useEffect(() => {
    if (!token) navigateTo("/auth/login");
  });

  return (
    <div>
      {token && (
        <Fragment>
          <AdminNavbar />
          <AdminSidebar />

          <div className="p-4 sm:ml-64 mt-16">
            <Outlet />
          </div>

          <BottomDrawer />
        </Fragment>
      )}
    </div>
  );
};
