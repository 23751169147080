import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { ModalContainer } from "../ModalContainer";
import { ModalContainerT } from "../../types";
import { useUploadMutation } from "../../hooks";
import { LargeSpinner } from "..";
import { ClientsPantnerData } from "../../types/ClientsPantner";

export const AddClientsPantnerModel = (
  props: Pick<ModalContainerT, "showModal" | "closeModal">
) => {
  const [formData, setFormData] = useState<ClientsPantnerData>({
    fullName: "",
    link: "",
    type: "",
    images: null,
    sinceWhen: "",
  });

  const mutation = useUploadMutation<ClientsPantnerData>({
    cacheId: "clients_partners",
    endpoint: "/clients_partners",
  });

  const handleUploadBlog = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    mutation.mutate({
      fullName: formData.fullName,
      link: formData.link,
      type: formData.type,
      sinceWhen: formData.sinceWhen,
      images: formData.images,
    });
  };

  const handleFormDataChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      props.closeModal();
      location.reload();
    }
  }, [mutation, props]);

  return (
    <ModalContainer
      modalTitle="Add Client-Partner"
      showModal={props.showModal}
      closeModal={props.closeModal}
    >
      {mutation.isPending ? (
        <LargeSpinner />
      ) : mutation.isSuccess ? (
        <h3>Client-Partner posted</h3>
      ) : null}
      <form className="py-3 px-5" onSubmit={handleUploadBlog}>
        <div className="mb-4 sm:w-[300px] lg:w-[350px]">
          <label
            htmlFor="title"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Full name
          </label>

          <input
            type="text"
            id="fullName"
            name="fullName"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
            placeholder="Full Name"
            value={formData.fullName}
            onChange={handleFormDataChange}
          />
        </div>

        {/* Rest of your form fields go here */}
        <div className="mb-4 sm:w-[300px] lg:w-[350px]">
          <label
            htmlFor="Website link"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Website link
          </label>

          <input
            type="text"
            id="Website link"
            name="link"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
            placeholder="Website link"
            value={formData.link}
            onChange={handleFormDataChange}
          />
        </div>

        <div className="mb-4 sm:w-[300px] lg:w-[350px]">
          <label
            htmlFor="Since When"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Since When
          </label>

          <input
            type="date"
            id="sinceWhen"
            name="sinceWhen"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
            placeholder="Since When"
            value={formData.sinceWhen}
            onChange={handleFormDataChange}
          />
        </div>

        <div className="mb-4 sm:w-[300px] lg:w-[350px]">
          <label
            htmlFor="status"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Type
          </label>

          <select
            id="type"
            name="type"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
            onChange={handleFormDataChange}
            value={formData.type || "default"}
          >
            <option value={"default"} disabled>
              __select status__
            </option>
            <option value="client">Client</option>
            <option value="partner">Partner</option>
          </select>
        </div>

        <div className="mb-4 sm:w-[300px] lg:w-[350px]">
          <label
            htmlFor="images"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Images
          </label>

          <input
            type="file"
            id="images"
            className="bg-gray-50 text-sm rounded block w-full"
            multiple
            accept="image/*"
            onChange={(event) =>
              setFormData((prevData) => ({
                ...prevData,
                images: event.target.files,
              }))
            }
          />
        </div>

        <div>
          <button className="w-full bg-primary py-2 rounded text-white tracking-wider">
            Upload Clients
          </button>
        </div>
      </form>
    </ModalContainer>
  );
};

// The rest of your code remains unchanged
