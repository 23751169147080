import microscope from "../assets/microscope.png";
import { FaMicroscope } from "react-icons/fa";
import { MdSupport } from "react-icons/md";

export const WhyChooseUs = () => {
  return (
    <div className="px-3 sm:px-16 lg:px-32 bg-white py-6 lg:py-14">
      <div className="relative z-10 grid grid-cols-1 sm:grid-cols-2 items-center justify-center gap-3 lg:gap-x-8 py-4">
        <div>
          <img
            src={microscope}
            alt="microscope"
            className="w-full h-full rounded object-contain lg:h-[380px]"
          />
        </div>

        <div className="text-green-600">
          <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold">
            Why Choose Us
          </h1>
          <span className="block w-3/4 my-2">
            Choose Pacific Diagnostic Ltd because we offer:
          </span>
          <div className="mt-5">
            <div className="flex items-center gap-x-3 mb-3">
              <div className="rounded outline outline-1 outline-gray-300 p-2">
                <FaMicroscope className="text-2xl" />
              </div>
              <div>
                <h6 className="text-gray-700 font-semibold">
                  Hight-Quality equipemt
                </h6>
                {/* <p className="text-gray-500 text-xs">
                  Lorem ipsum dolor sit amet consectetur.
                </p> */}
              </div>
            </div>

            <div className="flex items-center gap-x-3">
              <div className="rounded outline outline-1 outline-gray-300 p-2">
                <MdSupport className="text-2xl" />
              </div>
              <div>
                <h6 className="text-gray-700 font-semibold">
                  24 hours customer support
                </h6>
                <p className="text-gray-500 text-xs">
                  Reliable support for your equipment's peak performance.
                </p>
              </div>
            </div>
          </div>
          <button className="block bg-green-500 bg-opacity-80 hover:bg-opacity-100 px-8 lg:px-10 text-lg py-2 rounded-full text-white mt-4 lg:mt-8 transition-all duration-300 ease">
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};
