import { useState } from "react";
import { Laboratory1 } from "../../assets";
import { LargeSpinner } from "../../components";
import { useFetch } from "../../hooks";
import { GalleryT } from "../../types";
import { Nodata } from "../../components/cards";

export const Gallery = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");

  const query = useFetch<GalleryT>({
    endpoint: "/gallery",
    cacheId: "gallery",
  });

  const filterImagesByCategory = (category: string) => {
    if (!query.data) return [];

    if (category === "All") {
      return query.data.flatMap((gallery) => gallery.images);
    }
    return query.data
      .filter((gallery) => gallery.type === category)
      .flatMap((gallery) => gallery.images);
  };
  // const allImages = filterImagesByCategory("All");
  // const laboratoryImages = filterImagesByCategory("laboratory");
  // const radiologyImages = filterImagesByCategory("radiology");
  // console.log(radiologyImages);

  return (
    <>
      <div className="block text-center w-full px-6 py-3  bg-white  rounded-lg  dark:bg-green-800 ">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-green-900 dark:text-white">
          Explore Our Image Gallery
        </h5>
        <p className="font-normal text-green-700 dark:text-green-400">
          Discover a visual journey through our world of medical equipment and
          services. Browse our gallery below to see our commitment to
          excellence.
        </p>
      </div>
      <div className="flex items-center justify-center py-4 md:py-8 flex-wrap">
        <button
          type="button"
          onClick={() => setSelectedCategory("All")}
          className={`text-white hover:text-white border ${
            selectedCategory === "All"
              ? "border-green-600 bg-green-700"
              : "border-white bg-green-900"
          } hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 rounded-full text-base font-medium px-5 py-2.5 text-center mr-3 mb-3 dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-500 dark:bg-green-900 dark:focus:ring-green-800`}
        >
          Laboratory
        </button>
        {/* <button
          type="button"
          onClick={() => setSelectedCategory("laboratory")}
          className={`text-white border border-white hover:border-green-200 dark:border-green-900 dark:bg-green-900 dark:hover:border-green-700 ${
            selectedCategory === "laboratory" ? "bg-white" : "bg-green-700"
          } focus:ring-4 focus:outline-none focus:ring-green-300 rounded-full text-base font-medium px-2 py-2.5 text-center mr-3 mb-3 dark:text-white dark:focus:ring-green-800`}
        >
          Laboratory
        </button> */}
        <button
          type="button"
          onClick={() => setSelectedCategory("radiology")}
          className={`text-white border border-white hover:border-green-200 dark:border-green-900 dark:bg-green-900 dark:hover:border-green-700 ${
            selectedCategory === "radiology" ? "text-green-500" : "bg-green-700"
          } focus:ring-4 focus:outline-none focus:ring-green-300 rounded-full text-base font-medium px-2 py-2.5 text-center mr-3 mb-3 dark:text-white dark:focus:ring-green-800`}
        >
          Radiology
        </button>
      </div>

      {query.isPending ? (
        <LargeSpinner />
      ) : (
        <div>
          {query.isSuccess && (
            <div>
              {query.data.length ? (
                <div className="grid p-6 grid-cols-2 md:grid-cols-3 gap-4">
                  {filterImagesByCategory(selectedCategory).map((image) => (
                    <div key={image.id}>
                      <img
                        className="h-[320px] w-full rounded-lg object-cover"
                        src={
                          `https://api.pacificdiagnosticsmw.com/${image.url}` ||
                          Laboratory1
                        }
                        alt=""
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <Nodata />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};
