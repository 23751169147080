import { ReactNode } from "react";
type Props = {
  children: ReactNode;
  title: string;
};

export const AuthWrapper = ({ children, title }: Props) => {
  return (
    <div className="relative min-h-screen bg-login bg-no-repeat bg-contain bg-bottom py-16 px-4 grid place-content-center select-none">
      <div className="relative z-10">
        <h2 className="text-3xl lg:text-4xl font-bold tracking-widest text-white text-center mb-8">
          {title}
        </h2>

        {children}
      </div>

      <div className="absolute h-full w-full top-0 left-0 bg-primary  bg-opacity-50"></div>
    </div>
  );
};
