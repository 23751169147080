import { useState } from "react";
import { BlogCard, Nodata } from "../../components/cards";
import { useDeleteMutation, useFetch } from "../../hooks";
import { BlogT } from "../../types";
import { DeleteModal } from "../../components/modals";
import { LargeSpinner } from "../../components";

export const Blog = () => {
  const [showModal, setShowModal] = useState(false);

  const query = useFetch<BlogT>({
    endpoint: "/blog",
    cacheId: "blog",
  });
  
  const [selectedBlogId, setSelectedBlogId] = useState<number | null>(null);
 
  const deleteMutation = useDeleteMutation({
    endpoint: "/blog",
    cacheId: "blog",
  });

  const deleteBlog = () => {
    deleteMutation.mutate(selectedBlogId!);
    setShowModal(false);
  };

  return (
    <div className="w-full bg-[#f9f9f9] py-[50px]">
      {query.isPending || deleteMutation.isPending ? (
        <LargeSpinner />
      ) : (
        <div className="max-w-[1240px] mx-auto">
          <div>
            {query.isSuccess && (
              <div>
                {query.data.length ? (
                  <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 gap-8 px-4 text-black">
                    {query.data.map((blog) => (
                      <BlogCard
                        key={blog.id}
                        blog={blog}
                        selectFn={() => {
                          setSelectedBlogId(blog.id);
                          setShowModal(true);
                        }}
                      />
                    ))}
                  </div>
                ) : (
                  <Nodata />
                )}
              </div>
            )}
          </div>
        </div>
      )}

      <DeleteModal
        modalTitle="Delete blog"
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        deleteFn={deleteBlog}
      />
    </div>
  );
};
