import { Laboratory3 } from "../../assets";
import { LargeSpinner } from "../../components";
import { Nodata } from "../../components/cards";
import { useFetch } from "../../hooks";
import { AboutT } from "../../types/AboutT";

export const AboutCompany = () => {
  const query = useFetch<AboutT>({
    endpoint: "about",
    cacheId:"about"
  })
  return (
    <div>
      <div className="max-h-[500px] relative">
        <div className="absolute bg-green-500 bg-opacity-40 w-full h-full text-gray-200 max-h-[500px] flex flex-col justify-center lg:pl-10">
          <h1 className="px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold">
            Leading Medical Equipment
          </h1>
          <h1 className="px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold">
            Distributor In Africa
          </h1>
        </div>
        <img
          className="w-full max-h-[500px] object-cover"
          src={Laboratory3}
          alt="lab"
        />
      </div>

      <div className="pt-5 pb-10">
        {query.data?.length ? (
          query.isPending ? (
            <LargeSpinner />
          ) : (
            query.isSuccess &&
            query.data.map((about) => (
              <>
                <h1 className="text-green-600 font-bold text-2xl text-center my-3 uppercase">
                  {about.title}
                </h1>
                <div className="grid gap-y-6 px-4 sm:px-10 md:px-20 lg:px-44 xl:px-60">
                  <div
                    dangerouslySetInnerHTML={{ __html: about.description }}
                  />
                   <h5 className="mt-2 text-2xl font-bold tracking-tight text-gray-700 dark:text-white">
            Check us Google Map
          </h5>
        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3871.343678553856!2d33.760646775094756!3d-13.99762458642034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDU5JzUxLjUiUyAzM8KwNDUnNDcuNiJF!5e0!3m2!1sen!2smw!4v1706543002475!5m2!1sen!2smw" width="600" height="450" style={{border:"0px", display:"flex", width: "100%", margin:"1rem .5rem"}} allowFullScreen={false} loading="lazy" ></iframe>
                </div>
                
              </>
            ))
          )
        ) : (
          <Nodata />
        )}
      </div>
    </div>
  );
};
