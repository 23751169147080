import { useMutation, useQueryClient } from "@tanstack/react-query"
import { AxiosApi } from "../config"
import { HooksParamsT } from "../types"

export const useDeleteMutation = ({ endpoint, cacheId }: HooksParamsT) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (id: number) => AxiosApi.delete(`${endpoint}/${id}`).then(res => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [cacheId] })
        }
    })
}