import { Nodata, ProjectCard } from "../../components/cards";
import { useDeleteMutation, useFetch } from "../../hooks";
import { ProjectT } from "../../types";
import { LargeSpinner } from "../../components";
import { useState } from "react";
import { DeleteModal } from "../../components/modals";

export const AdminProjects = () => {
  const query = useFetch<ProjectT>({ endpoint: "/projects", cacheId: "projects" });
   const [showModal, setShowModal] = useState(false);
   const [selectedProjectId, setSelectedProjectId] = useState<number | null>(
     null
   );

   const deleteMutation = useDeleteMutation({
     endpoint: "/projects",
     cacheId: "projects",
   });

   const deleteProject = () => {
     deleteMutation.mutate(selectedProjectId!);
     setShowModal(false);
   };
  return (
    <div>
      {query.isPending ? (
        <LargeSpinner />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-6 gap-x-4">
          {query.isSuccess && (
            <div>
              {query.data.length ? (
                <div>
                  {query.data.map((project) => (
                    <ProjectCard
                      key={project.id}
                      project={project}
                      selectFn={() => {
                        setSelectedProjectId(project.id);
                        setShowModal(true);
                      }}
                    />
                  ))}
                </div>
              ) : (
                <Nodata />
              )}
            </div>
          )}
        </div>
      )}
      <DeleteModal
        modalTitle="Delete Project"
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        deleteFn={deleteProject}
      />
    </div>
  );
};
