type props = {
  name: string;
  webLink: string;
  image: string;
};

const SponsorCard = ({ name, image, webLink }: props) => {
  return (
    <div className="relative shadow bg-white rounded p-3 overflow-hidden group cursor-pointer border-b-2 border-primary">
      <div>
        <img
          src={image}
          alt={name}
          className={`w-full h-[90px] object-contain ${
            webLink === "https://www.unc.edu" ? "bg-[#4B9CD3] p-1 rounded" : ""
          }`}
        />
      </div>

      <div className="absolute h-full w-full top-0 left-0 flex items-center justify-center opacity-0 bg-gradient-to-br from-[#01d28c8c] to-[#2d3e50bd] group-hover:opacity-100 transition-opacity duration-200 ease">
        <a
          href={webLink}
          target="_blank"
          className="text-white text-center font-semibold text-1X1"
        >
          {name}
        </a>
      </div>
    </div>
  );
};

export default SponsorCard;
