import { useEffect, useState } from "react";
import { Carousel } from "flowbite-react";
import { useFetch } from "../../hooks";
import { ProjectT } from "../../types";
import { Nodata } from "../../components/cards";
import { useParams } from "react-router-dom";

export const ProjectDetails = () => {
  const [projectData, setProjectData] = useState<ProjectT | undefined>();
  const { projectId } = useParams();
  const { data } = useFetch<ProjectT>({
    endpoint: `projects/${projectId}`,
    cacheId: `project-${projectId}`,
  });

  useEffect(() => {
    if (data) {
      setProjectData((__prevState) => {
        // Access the first item if data is an array, or use data as is if it's a single object
        const project = Array.isArray(data) ? data[0] : data;
        return project;
      });
    }
  }, [data]);

  return (
    <div style={{ maxWidth: "1000px", margin: "auto" }} className="p-2">
      {projectData ? (
        <div className="w-full max-w-bg bg-white border border-green-200 rounded-lg shadow dark:bg-green-800 dark:border-green-700">
          <div
            style={{ height: "490px", maxWidth: "auto", margin: "auto" }}
            className="p-2"
          >
            <Carousel>
              {projectData.images.map((image) => {
                return (
                  <img
                    key={image.id}
                    style={{ height: "490px" }}
                    src={"https://api.pacificdiagnosticsmw.com/" + image.url}
                    alt=""
                  />
                );
              })}
            </Carousel>
          </div>
          <div className="px-5 pb-5">
            <div
              style={{ justifyContent: "space-between" }}
              className="flex w-full mt-2.5 mb-5"
            >
              <a href="#">
                <h5 className="text-xl mt-2.5 mb-5 font-semibold tracking-tight text-green-900 dark:text-white">
                  {projectData.title}
                </h5>
              </a>
              <div className="flex items-center mt-2.5 mb-5">
                {/* Star rating and other elements */}
              </div>
            </div>
            <div className=" items-center justify-between">
              <h6 className=" font-bold text-green-900 dark:text-white">
                {projectData.sub_title}
              </h6>
            </div>
            <div className=" items-center justify-between">
              <span className="text-sm  text-green-900 dark:text-white">
                <div
                  dangerouslySetInnerHTML={{ __html: projectData.description }}
                />
              </span>
            </div>
          </div>

          {projectData.videos   ? (
            <div
              style={{ height: "490px", maxWidth: "1000px", margin: "auto" }}
              className="p-2"
            >
              <Carousel slide={false} leftControl="Prev" rightControl="Next">
                {projectData.videos?.map((video) => (
                  <video
                    controls
                    key={video.id}
                    style={{ height: "490px" }}
                    src={"https://api.pacificdiagnosticsmw.com/" + video.url}
                  ></video>
                ))}
              </Carousel>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <Nodata />
      )}
    </div>
  );
};
