import { Link } from "react-router-dom";
import { pacificLogo } from "../../assets";
import { useState } from "react";

export const ClientNavbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  // Function to close the menu
  const closeMenu = () => {
    setShowMenu(false);
  };

  return (
    <nav className="bg-white dark:bg-gray-900 sticky w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <div>
          <img
            src={pacificLogo}
            className="h-8 w-[150px] lg:w-full object-contain mr-3"
            alt="pacific logo"
          />
        </div>

        <div className="flex md:order-2">
          <Link
            to={"/request-quotation"}
            className="flex items-center justify-center text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-2 sm:px-4 py-2 text-center mr-1 sm:mr-3 md:mr-0 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            Get Quote
          </Link>

          <button
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            onClick={() => setShowMenu((prev) => !prev)}
          >
            <span className="sr-only">Main Menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>

        <div
          className={`items-center justify-between w-full ${
            showMenu ? "fixed top-0 left-0" : "hidden"
          } md:flex md:w-auto md:order-1`}
          id="navbar-sticky"
        >
          {/* Close button */}
          <button
            type="button"
            className="md:hidden absolute top-4 right-4"
            onClick={closeMenu}
          >
            <svg
              className="w-5 h-5 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-600"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
            >
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>

          <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 w-[96%] mx-auto md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li>
              <Link
                to="/"
                className="block py-2 pl-3 pr-4 text-white bg-green-700 rounded md:bg-transparent md:text-green-700 md:p-0 md:dark:text-green-500"
                aria-current="page"
                onClick={closeMenu} // Close the menu when the Home link is clicked
              >
                Home
              </Link>
            </li>

            <li className="relative group cursor-pointer">
              <button className="w-full flex items-center justify-between mt-1 sm:mt-0 py-2 pl-3 pr-4 text-gray-900 rounded group-hover:bg-gray-100 md:group-hover:bg-transparent md:group-hover:text-green-700 md:p-0 md:dark:group-hover:text-green-500 dark:text-white dark:group-hover:bg-gray-700 dark:group-hover:text-white md:dark:group-hover:bg-transparent dark:border-gray-700">
                <span>About</span>
                <svg
                  className="w-2.5 h-2.5 ml-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>

              <ul className="hidden absolute w-full md:w-fit md:-left-1/2 top-full bg-white rounded-b px-5 py-6 group-hover:grid gap-y-2">
                <li>
                  <Link
                    to="/about/company"
                    className="block py-1 pl-3 text-green-700 md:p-0 md:dark:text-green-500"
                    aria-current="page"
                    onClick={closeMenu} // Close the menu when the Company link is clicked
                  >
                    Company
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about/offices"
                    className="block py-1 pl-3 text-green-700 md:p-0 md:dark:text-green-500"
                    aria-current="page"
                    onClick={closeMenu}
                  >
                    Offices
                  </Link>
                </li>

                <li>
                  <Link
                    to="/about/projects"
                    className="block py-1 pl-3 text-green-700 md:p-0 md:dark:text-green-500"
                    aria-current="page"
                    onClick={closeMenu}
                  >
                    Projects
                  </Link>
                </li>
                

                <li>
                  <Link
                    to="/about/team-details"
                    className="block py-1 pl-3 text-green-700 md:p-0 md:dark:text-green-500"
                    aria-current="page"
                    onClick={closeMenu}
                  >
                    Team
                  </Link>
                </li>

                <li>
                  <Link
                    to="/about/companies"
                    className="block py-1 pl-3 text-green-700 md:p-0 md:dark:text-green-500"
                    aria-current="page"
                    onClick={closeMenu}
                  >
                    Partners/Clients
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about/vacancies"
                    className="block py-1 pl-3 text-green-700 md:p-0 md:dark:text-green-500"
                    aria-current="page"
                    onClick={closeMenu}
                  >
                    Vacancies
                  </Link>
                </li>
              </ul>
            </li>
            {/* Other menu items */}

            <li>
              <Link
                to="/services"
                onClick={closeMenu}
                className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-green-700 md:p-0 md:dark:hover:text-green-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="/product"
                onClick={closeMenu}
                className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-green-700 md:p-0 md:dark:hover:text-green-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >
                Products
              </Link>
            </li>
            <li>
              <Link
                onClick={closeMenu}
                to="/gallery"
                className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-green-700 md:p-0 md:dark:hover:text-green-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >
                Gallery
              </Link>
            </li>
            <li>
              <Link
                to="/blog"
                onClick={closeMenu}
                className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-green-700 md:p-0 md:dark:hover:text-green-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                onClick={closeMenu}
                className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-green-700 md:p-0 md:dark:hover:text-green-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
