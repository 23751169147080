import { FC } from "react";
import { BlogT } from "../../types";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";

export const BlogCard: FC<{ blog: BlogT; selectFn?(): void }> = ({
  blog,
  selectFn,
}) => {
  const imageIndex = Math.floor(Math.random() * blog.images.length);
  const imageUrl = blog.images?.length
    ? `https://api.pacificdiagnosticsmw.com/${blog.images[imageIndex]?.url}`
    : undefined;
  // Split the description into words
  const words = blog.description.split(" ");

  // Select the first 20 words
  const truncatedDescription = words.slice(0, 20).join(" ");
  return (
    <div className="bg-white rounded-xl overflow-hidden drop-shadow-md pb-5">
      <Link
        to={"/blog/details/" + blog.id}
        onClick={() => {
          localStorage.setItem("blog-id", JSON.stringify(blog.id));
        }}
      >
        <img className="h-56 w-full object-cover" src={imageUrl} />
      </Link>

      <div className="px-8 py-2">
        <h3 className="font-bold text-2xl my-1">{blog.title}</h3>
        <div dangerouslySetInnerHTML={{ __html: truncatedDescription }} />
      </div>
      {selectFn && (
        <div className="flex items-center justify-center gap-x-2 mt-4">
          {/* <button className="bg-blue-600 text-white flex items-center gap-x-1 font-medium dark:text-blue-500 hover:text-white hover:bg-blue-500 rounded-lg p-2">
            <MdOutlineEdit className="text-xl" />
            edit
          </button> */}
          <button
            className="bg-red-600 flex items-center gap-x-1 font-medium text-white dark:text-red-500 hover:text-white hover:bg-red-500 rounded-lg p-2"
            onClick={selectFn}
          >
            <MdOutlineDeleteOutline className="text-xl" />
            delete
          </button>
        </div>
      )}
    </div>
  );
};
