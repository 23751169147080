export const ClientFooter = () => {
  return (
    <footer className="bg-green-400 dark:bg-gray-900">
      <div className="mx-auto w-full max-w-screen-xl">
        <div className="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-4">
          <div>
            <h2 className="mb-6 text-sm font-semibold text-gray-700 uppercase dark:text-white">
              Pacific Diagnostics Ltd
            </h2>
            <p className="text-white">
              Precision Medical Imaging and Surgical Excellence in Africa: Your
              Trusted Partner for Cutting-Edge Equipment and Expert
              Factory-Trained Service.
            </p>
            {/* <ul className="text-white font-medium">
              <li className="mb-4">
                <a href="#" className=" hover:underline">
                  About
                </a>
              </li>
              <li className="mb-4">
                <a href="#" className="hover:underline">
                  Careers
                </a>
              </li>
              <li className="mb-4">
                <a href="#" className="hover:underline">
                  Brand Center
                </a>
              </li>
              <li className="mb-4">
                <a href="#" className="hover:underline">
                  Blog
                </a>
              </li>
            </ul> */}
          </div>

          <div>
            <h2 className="mb-6 text-sm font-semibold text-gray-700 uppercase dark:text-white">
              Contact Us
            </h2>
            <ul className="text-white font-medium">
              <li className="mb-4">
                <a href="tel:+265994394970">+265994394970</a>
              </li>
              <li className="mb-4">
                <a href="mailto:khumbo.kaunda@pacificdiagnosticsmw.com">
                  Email
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="https://wa.me/+26599439497"
                  className="hover:underline"
                >
                  Whatsapp us
                </a>
              </li>
              {/* <li className="mb-4">
                <a href="#" className="hover:underline">
                  Contact Us
                </a>
              </li> */}
            </ul>
          </div>

          <div>
            <h2 className="mb-6 text-sm font-semibold text-gray-700 uppercase dark:text-white">
              Others Links
            </h2>
            <ul className="text-white font-medium">
              <li className="mb-1">
                <a href="services" className="hover:underline">
                  Services
                </a>
              </li>
              <li className="mb-1">
                <a href="/about/projects" className="hover:underline">
                  Projects
                </a>
              </li>
              <li className="mb-1">
                <a href="/product" className="hover:underline">
                  Products
                </a>
              </li>
              <li className="mb-1">
                <a href="/frequently-asked-question" className="hover:underline">
                  Frequently asked question
                </a>
              </li>
              <li className="mb-4">
                <a href="/blog" className="hover:underline">
                  Blog
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h2 className="mb-6 text-sm font-semibold text-gray-700 uppercase dark:text-white">
              Location
            </h2>
            <h3 className="text-white">Malawi</h3>
            <p className="text-white">
              Area 3, along Barron Avenue, plot 310, P.O Box
              1332, Lilongwe, Malawi
              
            </p>
          </div>
        </div>

        <div className="px-4 py-6 bg-green-500 md:flex md:items-center md:justify-between">
          <span className="text-sm text-white sm:text-center">
            {new Date().getFullYear()} <a href="">Pacific Diagnostics Ltd</a>.
            All Rights Reserved.
          </span>
          <div className="flex text-white mt-4 space-x-5 sm:justify-center md:mt-0">
            <p>Powered by</p>
            <a href="https://infi-tech.net" className="ml-2 hover:underline">
              INFI-TECH.NET
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
