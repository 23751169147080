import { useState } from "react";
import { LargeSpinner, ProductRow } from "../../components";
import { useDeleteMutation, useFetch } from "../../hooks";
import { ProductT } from "../../types";
import { DeleteModal } from "../../components/modals";

export const Products = () => {
  const query = useFetch<ProductT>({
    endpoint: "/product",
    cacheId: "products",
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState<number | null>(
    null
  );

  const deleteMutation = useDeleteMutation({
    endpoint: "/product",
    cacheId: "products",
  });

  const deleteProduct = () => {
    deleteMutation.mutate(selectedProductId!);
    setShowModal(false);
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
      {query.isPending || deleteMutation.isPending ? (
        <LargeSpinner />
      ) : (
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                <span className="sr-only">Image</span>
              </th>
              <th scope="col" className="px-6 py-3">
                Product
              </th>
              <th scope="col" className="px-6 py-3">
                Qty
              </th>
              <th scope="col" className="px-6 py-3">
                Price
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {query.isSuccess && (
              <>
                {query.data.length ? (
                  <>
                    {query.data.map((product) => (
                      <ProductRow
                        key={product.id}
                        product={product}
                        selectFn={() => {
                          setSelectedProductId(product.id);
                          setShowModal(true);
                        }}
                      />
                    ))}
                  </>
                ) : (
                  <p className="text-center p-10">Currently no information available</p>
                )}
              </>
            )}
          </tbody>
        </table>
      )}

      <DeleteModal
        modalTitle="Delete product"
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        deleteFn={deleteProduct}
      />
    </div>
  );
};
