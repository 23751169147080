// import { CompanyLogo1, CompanyLogo2, CompanyLogo3 } from "../../assets";
import Partners from "../../components/Partners";

export const Companies = () => {
  
  return (
    <Partners/>
    // <div className="w-full bg-white py-[50px]">
    //     <div className="md:max-w-[1400px] m-auto max-w-[600px] ">
    //         <h1 className="text-center text-2xl font-bold text-black uppercase">Sponsors/partners</h1>
    //         <div className='flex justify-center py-8 md:gap-8 sm:gap-8 cursor-pointer hover:duration-150'>
    //             <img 
    //             src={CompanyLogo1} />
    //             <img 
    //             src={CompanyLogo2} />
    //             <img 
    //             src={CompanyLogo3} />
    //         </div>
    //         <div className='flex justify-center py-8 md:gap-8 sm:gap-8 cursor-pointer hover:duration-150'>
    //             <img 
    //             src={CompanyLogo1} />
    //             <img 
    //             src={CompanyLogo2} />
    //             <img 
    //             src={CompanyLogo3} />
    //         </div>
    //         <div className='flex justify-center py-8 md:gap-8 sm:gap-8 cursor-pointer hover:duration-150'>
    //             <img 
    //             src={CompanyLogo1} />
    //             <img 
    //             src={CompanyLogo2} />
    //             <img 
    //             src={CompanyLogo3} />
    //         </div>
    //     </div>
    // </div>
  )
}
