import { MdCall, MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";

type props = {
  name: string;
  position: string;
  image: string;
  phoneNumber: string;
  email: string;
  description: string;
};

const TeamDetails = ({ name, position, image, phoneNumber,email, description }: props) => {
  return (
    <>
      <div className="relative overflow-hidden max-w-sm bg-white rounded-lg dark:bg-gray-800 dark:border-gray-700">
        <div>
          <img
            src={image}
            alt=""
            className="rounded-t-lg h-[280px] w-full object-cover brightness-75"
          />
        </div>
        <div className="py-2 text-center">
          <h5 className="text-gray-700 font-bold dark:text-white">{name}</h5>

          <span className="text-xs text-gray-500">{position}</span>
        </div>
        <div className="absolute top-4 right-3 flex flex-col gap-y-1 text-green-600 items-center cursor-pointer">
          <div className="relative overflow-hidden hover:overflow-visible bg-white rounded-full p-1 flex items-center gap-x-2 group">
            <span className="word absolute top-1/2 -left-2 px-3 rounded-3xl bg-white translate-y-0 -translate-x-full group-hover:-translate-y-1/2 transition-transform duration-300 ease">
              {phoneNumber}
            </span>
            <Link to={`tel:${phoneNumber}`}><MdCall className="text-lg" /></Link>
          </div>

          <div className="relative overflow-hidden hover:overflow-visible bg-white rounded-full p-1 flex items-center gap-x-2 group">
            <Link to={`mailto:${email}`} style={{zIndex: "2000"}} className="text-xs absolute top-1/2 -left-2 px-3 z-50 rounded-3xl bg-white translate-y-0 -translate-x-full group-hover:-translate-y-1/2 transition-transform duration-300 ease">
              {email}
            </Link>
            <Link to={`mailto:${email}`}><MdEmail className="text-lg" /></Link>
            
          </div>
        </div>
        <p className="text-left p-3">{description}</p>
      </div>
    </>
  );
};
export default TeamDetails;
