
import { useState } from "react";
import { LargeSpinner } from "../../components";
import { Nodata, QuoteAdminCard } from "../../components/cards";
import { DeleteModal } from "../../components/modals";
import { useDeleteMutation, useFetch } from "../../hooks";
import { QuoteT } from "../../types";


export const RequestQouteAdmin = () => {
  const query = useFetch<QuoteT>({ endpoint: "/quotes", cacheId: "quotes" });
  const [showModal, setShowModal] = useState(false);
  const [selecteQuoteId, setSelecteQuoteId] = useState<number | null>(null);

  const deleteMutation = useDeleteMutation({
    endpoint: "/quotes",
    cacheId: "quotes",
  });

  const deleteFaq = () => {
    deleteMutation.mutate(selecteQuoteId!);
    setShowModal(false);
  };

  return (
    <div className="mt-5 px-3 md:px-5 lg:px-10">
      {query.isPending || deleteMutation.isPending ? (
        <LargeSpinner />
      ) : (
        <div>
          {query.isSuccess && (
            <div>
              {query.data.length ? (
                <div>
                  {query.data.map((quote) => (
                    <QuoteAdminCard
                      key={quote.id}
                      quote={quote}
                      selectFn={() => {
                        setSelecteQuoteId(quote.id);
                        setShowModal(true);
                      }}
                    />
                  ))}
                </div>
              ) : (
                <Nodata />
              )}
            </div>
          )}
        </div>
      )}

      <DeleteModal
        modalTitle="Delete faq"
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        deleteFn={deleteFaq}
      />
    </div>

    // return (
    //   <>

    //     <QuoteAdminCard
    //       id={9}
    //       quantity={202}
    //       additional_details="hello ther this is addition infor"
    //       facility_details="hello ther this is addition infor"
    //       updated_at="03-32-9292-9"
    //       budget={9090}
    //       equipment_type="jhjjhjhj"
    //       contact_information="jkkj"
    //     />
    //     <QuoteAdminCard
    //       id={8}
    //       quantity={202}
    //       additional_details="hello ther this is addition infor"
    //       facility_details="hello ther this is addition infor"
    //       updated_at="03-32-9292-9"
    //       budget={9090}
    //       equipment_type="jhjjhjhj"
    //       contact_information="jkkj"
    //     />
    //   </>
  );
};
