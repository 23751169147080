import {
  AboutSection,
  HeroSection,
  TeamSection,
  WhyChooseUs,
  HomeTestimonial
} from "../../components";
import Partners from "../../components/Partners";

export const LandingPage = () => {
  return (
    <div>
      <HeroSection />

      <AboutSection />

      <WhyChooseUs />

      <HomeTestimonial />

      <TeamSection />
      <Partners />
    </div>
  );
};
