import { ChangeEvent, FormEvent, useState } from "react";
import { AxiosApi } from "../../config";
import {  toast } from "react-toastify";
// import { Helmet } from "react-helmet-async";
export const Contact = () => {
  // state for the form data
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    subject: "",
    phoneNumber: "",
    message: "",
  }) 
  const [isSuccess, setIsSuccess] = useState(false); 

  const formDataChange = <T extends HTMLTextAreaElement | HTMLInputElement>(
    event: ChangeEvent<T>
  ) => {
    const { name, value } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSendMessage = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      if (formDataValidation()) {
        const { data } = await AxiosApi.post("/contact", formData);
       setIsSuccess(true)
        console.log(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formDataValidation = () => {
    const { fullName, email, subject, message } = formData;
    if (!fullName) {
      toast.error("Please enter your full name");
      return false;
    } else if (!email) {
      toast.error("Please enter your email");
      return false;
    } else if (!subject) {
      toast.error("Please enter subject");
      return false;
    } else if (!message) {
      toast.error("Please enter a message");
      return false;
    } else return true;
  };

  return (
    <div
      style={{ maxWidth: "1000px", margin: "auto", marginTop: "4rem" }}
      className="xl:px-20 2xl:px-32, p-6 border border-gray-200"
    >
      {/* Set the title and description for the Contact Page */}
      {/* <Helmet>
        <title>Contact Us - Your Website Name</title>
        <meta
          name="description"
          content="Contact us for inquiries, support, or any questions you may have. We're here to assist you."
        />
      </Helmet> */}
      {!isSuccess ? (
        <div>
          <div className="block w-full pb-6  bg-white  rounded-lg  dark:bg-gray-800 ">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Get in Touch with Us
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              We're here to assist you! Whether you have questions, need
              assistance, or want to discuss your healthcare equipment needs,
              don't hesitate to contact us. Our dedicated team at Pacific
              Diagnostic Ltd is ready to provide the information and support you
              require. Feel free to reach out through the contact form below,
              via email, or by phone. Your healthcare journey begins with a
              simple message or call to us. We look forward to hearing from you
              and helping you achieve excellence in patient care
            </p>
          </div>
          <form onSubmit={handleSendMessage}>
            <div className="grid gap-6 mb-6 md:grid-cols-2">
              <div>
                <label
                  id="first_name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Full name
                </label>
                <input
                  type="text"
                  id="Full name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Full name"
                  required
                  name="fullName"
                  value={formData.fullName}
                  onChange={formDataChange}
                />
              </div>
              <div>
                <label
                  id="phoneNumber"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Email"
                  name="email"
                  required
                  value={formData.email}
                  onChange={formDataChange}
                />
              </div>
              <div>
                <label
                  id="phoneNumber"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="phoneNumber"
                  required
                  value={formData.phoneNumber}
                  onChange={formDataChange}
                />
              </div>
              <div>
                <label
                  id="Subject"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="subjext"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Subject"
                  required
                  name="subject"
                  value={formData.subject}
                  onChange={formDataChange}
                />
              </div>
            </div>

            <div>
              <label
                id="Additional Details"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Additional Details
              </label>
              <textarea
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary resize-none"
                id="Additional-Details"
                placeholder="Any specific features or preferences."
                cols={20}
                rows={7}
                name="message"
                required
                value={formData.message}
                onChange={formDataChange}
              />
            </div>
            <button
              type="submit"
              className="mt-5 text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </form>
          <h5 className="mt-2 text-2xl font-bold tracking-tight text-gray-700 dark:text-white">
            Check us Google Map
          </h5>
        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3871.343678553856!2d33.760646775094756!3d-13.99762458642034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDU5JzUxLjUiUyAzM8KwNDUnNDcuNiJF!5e0!3m2!1sen!2smw!4v1706543002475!5m2!1sen!2smw" width="600" height="450" style={{border:"0px", display:"flex", width: "100%", margin:"1rem .5rem"}} allowFullScreen={false} loading="lazy" ></iframe>

        </div>
      ) : (
        <div className="block w-full pb-6  bg-white  rounded-lg  dark:bg-gray-800 ">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-700 dark:text-white">
            Message Sent Successfully
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            Thank you for reaching out to us! Your message has been successfully
            received. We'll review it and get back to you soon. In the meantime,
            feel free to explore our website or check out our latest updates. We
            appreciate your interest in PACIFIC DIAGNOSTICS LTD
          </p>
        </div>
      )}
    </div>
    // <div className="px-3 w-full sm:px-16 lg:px-32 py-6 lg:py-14">
    //   <div className="flex w-full place-content-center">
    //     <form
    //       className="bg-white rounded-md py-6 px-5 lg:px-10"
    //       onSubmit={handleSendMessage}
    //     >
    //       <div className="mb-3 sm:w-[300px] lg:w-[350px]">
    //         <label
    //           htmlFor="username"
    //           className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
    //         >
    //           Username
    //         </label>

    //         <input
    //           type="text"
    //           id="username"
    //           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
    // placeholder="Username"
    // name="username"
    // value={formData.username}
    // onChange={formDataChange}
    //         />
    //       </div>

    //       <div className="mb-3 sm:w-[300px] lg:w-[350px]">
    //         <label
    //           htmlFor="email"
    //           className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
    //         >
    //           Email
    //         </label>

    //         <input
    //           type="email"
    //           id="email"
    //           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
    //           placeholder="Email Address"
    // name="email"
    // value={formData.email}
    // onChange={formDataChange}
    //         />
    //       </div>

    //       <div className="mb-3 sm:w-[300px] lg:w-[350px]">
    //         <label
    //           htmlFor="subject"
    //           className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
    //         >
    //           Subject
    //         </label>

    //         <input
    //           type="text"
    //           id="subject"
    //           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
    //           placeholder="Subject"
    // name="subject"
    // value={formData.subject}
    // onChange={formDataChange}
    //         />
    //       </div>

    //       <div className="mb-3 sm:w-[300px] lg:w-[350px]">
    //         <label
    //           htmlFor="message"
    //           className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
    //         >
    //           Message
    //         </label>

    //         <textarea
    //           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary resize-none"
    //           id="message"
    //           placeholder="Type your message"
    //           cols={20}
    //           rows={7}
    // name="message"
    // value={formData.message}
    // onChange={formDataChange}
    //         />
    //       </div>

    //       <div className="mt-5">
    //         <button className="tracking-widest text-white bg-primary focus:ring-4 focus:outline-none focus:ring-green-300 font-semibold rounded text-sm px-5 w-1/2 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
    //           Send Message
    //         </button>
    //       </div>
    //     </form>
    //   </div>

    //   <ToastContainer
    //     position="top-center"
    //     autoClose={5000}
    //     hideProgressBar={false}
    //     newestOnTop={false}
    //     closeOnClick
    //     rtl={false}
    //     limit={1}
    //     pauseOnFocusLoss
    //     draggable
    //     pauseOnHover
    //     theme="colored"
    //   />
    // </div>
  );
};
