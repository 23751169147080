/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@tanstack/react-query'
import { AxiosApi } from '../config'
import { AxiosResponse } from 'axios'
import { HooksParamsT } from '../types'

export const useFetch = <T>({ endpoint, cacheId }: HooksParamsT) => {
    return useQuery({
        queryKey: [cacheId],
        queryFn: () => AxiosApi.get<any, AxiosResponse<{ data: T[] }>>(endpoint).then(res => res.data.data)
         
    })
    console.log(useQuery);
}
