import { ClientNavbar, ClientFooter } from "../../layout";
import { Outlet } from "react-router-dom";

export const ClientRoot = () => {
  return (
    <div>
      <ClientNavbar />

      <Outlet />

      <ClientFooter />
    </div>
  );
};
