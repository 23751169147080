import { FC } from "react";
import { ModalContainerT } from "../types/ModalContainerT";

export const ModalContainer: FC<ModalContainerT> = (props) => {
  return (
    <div
      className={`fixed top-0 left-0 right-0 z-50 ${
        props.showModal ? "grid place-content-center" : "hidden"
      } w-full bg-black/30 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full max-h-full`}
    >
      <div className="relative w-fit max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 transition-all duration-500 ease">
          <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              {props.modalTitle}
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={props.closeModal}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          {props.children}
        </div>
      </div>
    </div>
  );
};
