import { FC, useState } from "react";
import { ProductT } from "../types";

export const ProductRow: FC<{ product: ProductT; selectFn(): void }> = ({
  product,
  selectFn,
}) => {
  const [quantity, setQuantity] = useState(product.quantity);

  const imageIndex = Math.floor(Math.random() * product.images.length);
  const imageUrl = product.images?.length
    ? `https://api.pacificdiagnosticsmw.com/${product.images[imageIndex]?.url}`
    : undefined;

  return (
    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
      <td className="w-32 p-4">
        <img src={imageUrl} alt="Apple Watch" className="rounded-xl" />
      </td>

      <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
        {product.title}
      </td>

      <td className="px-6 py-4">
        <div className="flex items-center space-x-3">
          <button
            className="inline-flex items-center justify-center p-1 text-sm font-medium h-6 w-6 text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
            onClick={() => setQuantity((prevValue) => prevValue - 1)}
            disabled={quantity === 0}
          >
            <span className="sr-only">Quantity button</span>
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 18 2"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h16"
              />
            </svg>
          </button>
          <div>
            <input
              type="number"
              id="first_product"
              className="bg-gray-50 w-24 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="1"
              value={quantity}
              onChange={(event) => setQuantity(parseInt(event.target.value))}
            />
          </div>
          <button
            className="inline-flex items-center justify-center h-6 w-6 p-1 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
            onClick={() => setQuantity((prevValue) => prevValue + 1)}
          >
            <span className="sr-only">Quantity button</span>
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 18 18"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 1v16M1 9h16"
              />
            </svg>
          </button>
        </div>
      </td>

      <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
        $ {product.price}
      </td>

      <td className="px-6 py-4">
        <div className="flex items-center">
          <div className="h-2.5 w-2.5 rounded-full bg-green-500 mr-2"></div>
          <span>{product.availability}</span>
        </div>
      </td>

      <td className="px-6 py-4">
        <div className="flex items-center gap-x-2">
          {/* <button className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
            edit
          </button> */}
          <button
            className="font-medium text-red-600 dark:text-red-500 hover:underline"
            onClick={selectFn}
          >
            Delete
          </button>
        </div>
      </td>
    </tr>
  );
};
