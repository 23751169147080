import { FaQuestionCircle } from "react-icons/fa";
import {  MdOutlineDeleteOutline } from "react-icons/md";
import { FaqT } from "../../types";
import { FC } from "react";

export const FAQCard: FC<{ faq: FaqT; selectFn(): void }> = ({
  faq,
  selectFn,
}) => {
  return (
    <div
      style={{ maxWidth:"1000px", margin:"auto"}}
      className="outline  outline-1 outline-gray-200 rounded-lg px-3 py-3"
    >
      <div className="flex items-center gap-3 text-gray-600">
        <FaQuestionCircle className="text-3xl" />
        <h3 className="text-xl font-semibold">{faq.question}</h3>
      </div>

      <p className="text-gray-500 mt-4">{faq.answer}</p>

      <div className="flex items-center justify-center gap-x-2 mt-4">
        {/* <button className="bg-green-600 text-white flex items-center gap-x-1 font-medium dark:text-green-500 hover:text-white hover:bg-green-500 rounded-lg p-2">
          <MdOutlineEdit className="text-xl" />
          edit
        </button> */}
        <button
          onClick={selectFn}
          className="bg-red-600 flex items-center gap-x-1 font-medium text-white dark:text-red-500 hover:text-white hover:bg-red-500 rounded-lg p-2"
        >
          <MdOutlineDeleteOutline className="text-xl" />
          delete
        </button>
      </div>
    </div>
  );
};
