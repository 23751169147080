/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from "react-router-dom";
import { FormEvent, useState, useRef } from "react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { Loader } from "../../components";
import { toast, ToastContainer, Flip } from "react-toastify";
import { AxiosApi } from "../../config";
import { AuthWrapper } from "../../layout";
import { AxiosResponse } from "axios";
import { useLocalStorage } from "../../hooks";

export const Adminlogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  // the state for controlling the loading of the auth
  const [isLoading, setIsLoading] = useState(false);
  // could have used state but am avoiding uncesessary re-rendering
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const { saveToken } = useLocalStorage();
  const navigateTo = useNavigate();

  /* Will use RQ for this api call */
  // making the api call to let the admin login to the dashboard
  const handleAdminLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      if (validateFormData()) {
        setIsLoading(true);
        const { data } = await AxiosApi.post<
          unknown,
          AxiosResponse<{ token: string }>
        >("https://api.pacificdiagnosticsmw.com/api/v1/login", {
          email: emailRef.current?.value,
          password: passwordRef.current?.value,
        });

        setIsLoading(false);

        saveToken(data.token);
        navigateTo("/admin");
      }
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error.error);
    }
  };

  // could have used a form validation library but utilizing the amount of libraries available is important
  const validateFormData = () => {
    if (!emailRef.current?.value) {
      toast.error("Please enter your email");

      return false;
    } else if (!passwordRef.current?.value) {
      toast.error("Please enter your password");

      return false;
    } else return true;
  };

  return (
    <div>
      <AuthWrapper title="Let's get started">
        <form
          className="bg-white rounded-md py-6 px-5 lg:px-10"
          onSubmit={handleAdminLogin}
        >
          <div className="mb-4 sm:w-[300px] lg:w-[350px]">
            <label
              htmlFor="email"
              className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
            >
              Email
            </label>

            <input
              type="text"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
              placeholder="Email Adress"
              ref={emailRef}
            />
          </div>

          <div className="mb-1 sm:w-[300px] lg:w-[350px]">
            <label
              htmlFor="password"
              className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
            >
              Password
            </label>

            <div className="relative">
              <input
                type={`${showPassword ? "text" : "password"}`}
                id="password"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
                placeholder="Password"
                ref={passwordRef}
              />

              <div className="absolute top-1/2 right-4 -translate-y-1/2">
                {showPassword ? (
                  <BsFillEyeFill
                    className="text-2xl p-1 text-primary cursor-pointer rounded-full hover:bg-gray-200"
                    onClick={() => setShowPassword(false)}
                  />
                ) : (
                  <BsFillEyeSlashFill
                    className="text-2xl p-1 text-primary cursor-pointer rounded-full hover:bg-gray-200"
                    onClick={() => setShowPassword(true)}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center gap-y-2 mt-5">
            <button className="tracking-widest text-white bg-primary focus:ring-4 focus:outline-none focus:ring-green-300 font-semibold rounded text-sm px-5 w-1/2 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              Login
            </button>
            {/* <Link
              to="/auth/forgot-password"
              className="text-blue-600 hover:underline dark:text-blue-500"
            >
              Forgot password?
            </Link> */}
          </div>
        </form>
      </AuthWrapper>

      <Loader loading={isLoading} />

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Flip}
      />
    </div>
  );
};
