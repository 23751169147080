import { FC } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { QuoteT } from "../../types";

export const QuoteAdminCard: FC<{quote: QuoteT; selectFn(): void}>= ({
    quote, selectFn,}) =>{
  return (
    <ol className="relative mb-10 border-l border-gray-200 dark:border-gray-700">
      <li className="mb-1 ml-6">
        <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
          <svg
            className="w-2.5 h-2.5 text-blue-800 dark:text-blue-300"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
          </svg>
        </span>
        <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">
          Quotation ID: {quote.id}
        </h3>

        <hr />
        <br />
        <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
          Created on {quote.updated_at}
        </time>
        <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
          Contact info: {quote.contact_information}
        </p>
      </li>
      <li className="mb-10 ml-6">
        <p className="text-base font-normal text-gray-500 dark:text-gray-400">
          Budget: {quote.budget}
        </p>
        <p className="text-base font-normal text-gray-500 dark:text-gray-400">
          Quantity: {quote.quantity}
        </p>
        <p className="text-base font-normal text-gray-500 dark:text-gray-400">
          Equipment type: {quote.equipment_type}
        </p>
        <p className="text-base font-normal text-gray-500 dark:text-gray-400">
          Facility Details: {quote.facility_details}
        </p>
        <br />
        <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
          Additional details
        </time>
        <p className="text-base font-normal text-gray-500 dark:text-gray-400">
          {quote.additional_details}
        </p>
      </li>
      <div className="flex items-center justify-center gap-x-2 mt-4">
        {/* <button className="bg-green-600 text-white flex items-center gap-x-1 font-medium dark:text-green-500 hover:text-white hover:bg-green-500 rounded-lg p-2">
          <MdOutlineEdit className="text-xl" />
          edit
        </button> */}
        <button
          onClick={selectFn}
          className="bg-red-600 flex items-center gap-x-1 font-medium text-white dark:text-red-500 hover:text-white hover:bg-red-500 rounded-lg p-2"
        >
          <MdOutlineDeleteOutline className="text-xl" />
          delete
        </button>
      </div>
    </ol>
  );
}
