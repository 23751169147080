import { useEffect, useState } from "react";
import { Carousel } from "flowbite-react";
import { ProductT } from "../../types";
import { AxiosApi } from "../../config";
import { useParams } from "react-router-dom";

export const ProductDetails = () => {
  const [productData, setProductData] = useState<ProductT | undefined>();
  const { producId } = useParams();
  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await AxiosApi.get<ProductT>(`product/${producId}`);

        // Check if the response data is an array or a single object
        const data = Array.isArray(response.data)
          ? response.data[0]
          : response.data;

        setProductData(data);
      } catch (error) {
        console.error("Error fetching blog:", error);
        // Handle error, e.g., show an error message
      }
    };

    fetchBlog();
  }, []);
  console.log(productData);

  return (
    <div style={{ maxWidth: "1000px", margin: "auto" }} className="p-2">
      {productData ? (
        <div className="w-full max-w-bg bg-white border border-green-200 rounded-lg shadow dark:bg-green-800 dark:border-green-700">
          <div
            style={{ height: "490px", maxWidth: "1000px", margin: "auto" }}
            className="p-2"
          >
            <Carousel>
              {productData.images.map((image) => {
                return (
                  <img
                    key={image.id}
                    style={{ height: "490px" }}
                    src={"https://api.pacificdiagnosticsmw.com/" + image.url}
                    alt=""
                  />
                );
              })}
            </Carousel>
          </div>
          <div className="px-5 pb-5">
            <div
              style={{ justifyContent: "space-between" }}
              className="flex w-full mt-2.5 mb-5"
            >
              <a href="#">
                <h5 className="text-xl mt-2.5 mb-5 font-semibold tracking-tight text-green-900 dark:text-white">
                  {productData.title}
                </h5>
              </a>
              <div className="flex items-center mt-2.5 mb-5">
                {/* {productData.updated_at} */}
              </div>
            </div>

            <div className=" items-center justify-between">
              <span className="text-sm  text-green-900 dark:text-white">
                <div
                  dangerouslySetInnerHTML={{ __html: productData.description }}
                />
              </span>
            </div>
          </div>
        </div>
      ) : (
        // You can add a loading indicator or error message here
        <div>Loading...</div>
      )}
    </div>
  );
};
