

export const useLocalStorage = () => {
    const saveToken = (token: string) => {
        localStorage.setItem("pacific-access-token", token)
    }

    const getToken = () => {
        const token = localStorage.getItem("pacific-access-token")

        return token
    }

    return { saveToken, getToken }
}