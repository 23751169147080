import { MdEmail, MdCall } from "react-icons/md";
import { TeamCardT } from "../../types";
import { Link } from "react-router-dom";

export const TeamCard = (props: TeamCardT) => {
  return (
    <div className="relative bg-white rounded-lg dark:bg-gray-800 dark:border-gray-700">
      <div>
        <img
          src={props.image}
          alt=""
          className="rounded-t-lg h-[280px] w-full object-cover brightness-75"
        />
      </div>
      <div className="py-2 text-center">
        <h5 className="text-gray-700 font-bold dark:text-white">
          {props.name}
        </h5>

        <span className="text-xs text-gray-500">{props.position}</span>
      </div>
      <div className="absolute top-4 right-3 flex flex-col gap-y-1 text-green-600 items-center cursor-pointer">
        <div className="relative overflow-hidden hover:overflow-visible bg-white rounded-full p-1 flex items-center gap-x-2 group">
          <span className="absolute text-xs top-1/2 -left-2 px-3 rounded-3xl bg-white translate-y-0 -translate-x-full group-hover:-translate-y-1/2 transition-transform duration-300 ease">
            {props.phoneNumber}
          </span>
          <Link to={`tel:${props.phoneNumber}`}><MdCall className="text-lg" /></Link>
        </div>

        {props.emailAddress && (
          <div className="relative overflow-hidden hover:overflow-visible bg-white rounded-full p-1 flex items-center gap-x-2 group">
            <Link to={`mailto:${props.emailAddress}`} style={{zIndex: "2000"}} className="text-xs absolute top-1/2 -left-2 px-3 z-50 rounded-3xl bg-white translate-y-0 -translate-x-full group-hover:-translate-y-1/2 transition-transform duration-300 ease">
              {props.emailAddress}
            </Link>
            <Link to={`mailto:${props.emailAddress}`}><MdEmail className="text-lg" /></Link>
            
          </div>
        )}
      </div>
    </div>
  );
};
