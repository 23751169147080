import { useMutation, useQueryClient } from "@tanstack/react-query"
import { HooksParamsT } from "../types"
import { AxiosApi } from "../config"

export const useUploadMutation = <T>({ endpoint, cacheId }: HooksParamsT) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (uploadData: T) => AxiosApi.postForm(endpoint, uploadData).then(res => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [cacheId] })
        }
    })
}