import { Link } from "react-router-dom";
import { OfficeCardT } from "../../types";
import { MdEmail,  } from "react-icons/md";

export const OfficeCard = ({
  countryName,
  flagImage,
  officeLocation,
  officeNumber,
  email,
}: OfficeCardT) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
      <div>
        <img
          src={flagImage}
          alt={`${countryName}-flag`}
          className="h-[250px] w-full rounded-lg object-cover"
        />
      </div>

      <div>
        <h3 className="text-gray-800 text-xl font-semibold tracking-widest mb-2">
          {countryName}
        </h3>
        <p className="text-gray-500 my-2">{officeLocation}</p>
        <p className="text-gray-500 flex items-center gap-x-1 mb-2">
          
          
          <span><Link to={`tel:${officeNumber}`}>{officeNumber}</Link></span>
        </p>
        <p className="text-gray-500 flex flex-wrap items-center gap-x-1">
          <MdEmail />
          <span className={email.includes("pacificdiagnosticsmw.com") ? "text-sm" : ""}>
           <Link to={`mailto:${email}`}>{email}</Link>
          </span>
        </p>
      </div>
    </div>
  );
};
