import { LargeSpinner } from ".";
import { Laboratory10 } from "../assets";

import { useFetch } from "../hooks";
import { CleintsPartnersT } from "../types/CleintsPartnersT";
import { Nodata } from "./cards";
import SponsorCard from "./cards/SponsorCard";

const Partners = () => {
  const query = useFetch<CleintsPartnersT>({
    endpoint: "/clients_partners",
    cacheId: "clients_partners",
  });
  console.log(query);

  return (
    <div className="bg-white py-10">
      <h3 className="capitalize text-center text-green-600 text-2xl md:text-3xl lg:text-4xl font-bold mb-3">
        Partners / Clients
      </h3>
      {query.isPending ? (
        <LargeSpinner />
      ) : (
        <div>
          {query.isSuccess && (
            <div>
              {query.data.length ? (
                <div className="w-full grid gap-y-4 gap-x-3 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 sm:gap-x-3 xl:gap-x-10 xl:gap-y-6 lg:gap-x-4 px-[1.5rem] sm:px-6 xl:px-36 py-8">
                  {query.data.map((Partners) => (
                    <SponsorCard
                      key={Partners.id}
                      image={
                        Partners.images[0]
                          ? `https://api.pacificdiagnosticsmw.com/${Partners.images[0].url}`
                          : Laboratory10
                      }
                      name={Partners.fullName}
                      webLink={Partners.link}
                    />
                  ))}
                </div>
              ) : (
                <Nodata />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Partners;
