
import { Outlet } from 'react-router-dom'

export const ProductsRoot = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
};
