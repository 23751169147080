import { MdOutlineDeleteOutline} from "react-icons/md";

export const ServiceCard = ({title, description, images, selectFn} ) => {
console.log(selectFn);

return (
  <div className="w-full py-[10rem] px-4 bg-green-100">
    <div className="max-w-[1240px] mx-auto">
      <div className=" bg-white w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
        <img
          className="h-500 rounded-2xl mx-auto mt-[-3rem] bg-white"
          src={images}
        />
        <h2 className="text-2xl font-bold text-center py-8">{title}</h2>
        <div className="text-center font-medium services-card">
          <div dangerouslySetInnerHTML={{ __html: description }} />
          {selectFn && (
            <div className="flex items-center justify-center gap-x-2 mt-4">
              {/* <button className="bg-blue-600 text-white flex items-center gap-x-1 font-medium dark:text-blue-500 hover:text-white hover:bg-blue-500 rounded-lg p-2">
                <MdOutlineEdit className="text-xl" />
                edit
              </button> */}
              <button
                className="bg-red-600 flex items-center gap-x-1 font-medium text-white dark:text-red-500 hover:text-white hover:bg-red-500 rounded-lg p-2"
                onClick={selectFn}
              >
                <MdOutlineDeleteOutline className="text-xl" />
                delete
              </button>
            </div>
          )}
        </div>
      </div>

      {/* <div className='bg-white w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
              <img className='w-30 rounded-2xl mx-auto mt-[-3rem] bg-white' 
              src={Laboratory14}/>
              <h2 className='text-2xl font-bold text-center py-8'>INTENSIVE CARE</h2>
              <div className='text-center font-medium'>
                  <p className='py-2 border-b mx-8 mt-8'>Intensive Care Ventilators</p>
                  <p className='py-2 border-b mx-8'>ICU Patient monitors</p>
                  <p className='py-2 border-b mx-8'>Central Monitoring System</p>
                  <p className='py-2 border-b mx-8'>Nurse Call System</p>
                  <p className='py-2 border-b mx-8'>ICU Beds</p>
                  <p className='py-2 border-b mx-8'>ICU Pendants</p>
                  <p className='py-2 border-b mx-8'>Syringe Pump</p>
                  <p className='py-2 border-b mx-8'>Infusion Pump</p>
                  <p className='py-2 border-b mx-8'>Defibrillator & AED (Automated Electronic Defibrillator)</p>
                  <p className='py-2 border-b mx-8'>Emergency Cart</p>
                  <p className='py-2 border-b mx-8'>Suction Machine</p>
              </div>
          </div>

          <div className='bg-white w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
              <img className='w-30 rounded-2xl mx-auto mt-[-3rem] bg-white' 
              src={Laboratory12}/>
              <h2 className='text-2xl font-bold text-center py-8'>NEONETAL AND PEDIATRIC INTESIVE CARE UNIT</h2>
              <div className='text-center font-medium'>
                  <p className='py-2 border-b mx-8 mt-8'>Neonatal Ventilator</p>
                  <p className='py-2 border-b mx-8'>Patient Monitor</p>
                  <p className='py-2 border-b mx-8'>Incubator</p>
                  <p className='py-2 border-b mx-8'>Baby Warmer</p>
                  <p className='py-2 border-b mx-8'>Resuscitator</p>
                  <p className='py-2 border-b mx-8'>Delivery Beds</p>
              </div>
          </div>

          <div className='bg-white w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
              <img className='w-30 rounded-2xl mx-auto mt-[-3rem] bg-white' 
              src={Laboratory9}/>
              <h2 className='text-2xl font-bold text-center py-8'>OPERATING THETARE</h2>
              <div className='text-center font-medium'>
                  <p className='py-2 border-b mx-8 mt-8'>Anesthesia Ventilator</p>
                  <p className='py-2 border-b mx-8'>Patient Vital Sign Monitor</p>
                  <p className='py-2 border-b mx-8'>Anesthesia Gas Monitor</p>
                  <p className='py-2 border-b mx-8'>Operating Theatre Table</p>
                  <p className='py-2 border-b mx-8'>Resuscitator</p>
                  <p className='py-2 border-b mx-8'>Operating Theatre Light</p>
                  <p className='py-2 border-b mx-8'>Operating Theatre Pendants</p>
                  <p className='py-2 border-b mx-8'>Surgical Diathermy Machine</p>
                  <p className='py-2 border-b mx-8'>Suction Machine</p>
                  <p className='py-2 border-b mx-8'>Endoscope Machine</p>
              </div>
          </div>

          <div className='bg-white w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
              <img className='w-30 rounded-2xl mx-auto mt-[-3rem] bg-white' 
              src={Laboratory10}/>
              <h2 className='text-2xl font-bold text-center py-8'>DENTAL</h2>
              <div className='text-center font-medium'>
                  <p className='py-2 border-b mx-8 mt-8'>5Dental chair and instruments</p>
                  <p className='py-2 border-b mx-8'>Dental X-ray</p>
              </div>
          </div> */}
    </div>
  </div>
);
}
