import { useFetch } from "../hooks";
import { TestimonialT } from "../types";
import { Nodata, TestimonialCard } from "./cards";

export const HomeTestimonial = () => {
  const query = useFetch<TestimonialT>({
    endpoint: "/testimonials",
    cacheId: "testimonials",
  });

  return (
    <div className="h-fit py-8 bg-gray-50">
      <h3 className="capitalize text-center text-green-600 text-2xl md:text-3xl lg:text-4xl xl:text-[2.5rem] font-bold mb-3">
        Customer Feedback
      </h3>
      <swiper-container
        class="lg:px-32"
        slides-per-view={1}
        space-between={10}
        centered-slides={true}
        navigation="true"
        autoplay-delay="3500"
        loop="true"
        autoplay-disable-on-interaction="false"
      >
        {query.data?.length ? (
          <div>
            {query.isSuccess &&
              query.data.map((testimonial, index: number) => (
                <swiper-slide>
                  <TestimonialCard
                    key={index}
                    testmonial={testimonial}
                    targetUser="client"
                  />
                </swiper-slide>
              ))}
          </div>
        ) : (
          <Nodata />
        )}
      </swiper-container>
    </div>
  );
};
