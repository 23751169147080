 type props ={
    quastion: string;
    answer: string
}

export const FAQClient = ({answer, quastion}: props) => {
  return (
    <div className="w-full m-2  bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <div className="flex flex-col items-center pb-10">
        <h5 className="mb-1 p-2 text-xl font-medium text-gray-900 dark:text-white">
          Q: {quastion}
        </h5>
        <span className="text-sm text-gray-500 p-3 dark:text-gray-400">
          Ans: {answer}
        </span>
      </div>
    
    </div>
  );
}
