import { Link } from "react-router-dom";
import {
  Laboratory14,
  Laboratory1,
  Laboratory5,
  Laboratory12,
  Laboratory7,
  Laboratory13,
} from "../assets";

export const HeroSection = () => {
  return (
    <div className="relative px-3 sm:px-16 lg:px-32 bg-green-50">
      <swiper-container
        slides-per-view={1}
        space-between={10}
        centered-slides={true}
        autoplay-delay="4500"
        loop="true"
        pagination="true"
        autoplay-disable-on-interaction="false"
        effect="cube"
      >
        <swiper-slide>
          <div className="relative z-10 grid grid-cols-1 sm:grid-cols-2 items-center justify-center gap-3 lg:gap-x-8 py-4 px-4">
            <div className="text-green-600">
              <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-gray-700">
                Leading Medical <br /> Equipment Distributor
              </h1>
              <span className="block w-3/4 mt-3">
                Pacific Diagnostic Ltd is a prominent distributor of medical
                equipment, accessories, and consumables in Africa.
              </span>

              <Link to={"request-quotation"} className="block outline w-fit outline-2 outline-green-500 hover:bg-green-500 px-8 lg:px-10 text-lg py-2 rounded-full hover:text-white mt-4 lg:mt-8 transition-all duration-300 ease">
                Get Quote
              </Link>
            </div>
            <div className="py-5">
              <img
                src={Laboratory1}
                alt="lab"
                className="w-full h-full rounded"
              />
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div className="relative z-10 grid grid-cols-1 sm:grid-cols-2 items-center justify-center gap-3 lg:gap-x-8 py-4 px-4">
            <div className="text-green-600">
              <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-gray-700">
                Comprehensive Range of Medical Equipment
              </h1>
              <span className="block w-3/4 mt-3">
                One-stop shop for all healthcare equipment needs.
              </span>

              <Link to={"request-quotation"} className="block outline w-fit outline-2 outline-green-500 hover:bg-green-500 px-8 lg:px-10 text-lg py-2 rounded-full hover:text-white mt-4 lg:mt-8 transition-all duration-300 ease">
                Get Quote
              </Link>
            </div>
            <div className="py-5">
              <img
                src={Laboratory14}
                alt="lab"
                className="w-full h-full rounded"
              />
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div className="relative z-10 grid grid-cols-1 sm:grid-cols-2 items-center justify-center gap-3 lg:gap-x-8 py-4 px-4">
            <div className="text-green-600">
              <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-gray-700">
                Leading Expertise in Medical Imaging
              </h1>
              <span className="block w-3/4 mt-3">
                Precision in diagnostics with cutting-edge imaging solutions.
              </span>

              <Link to={"request-quotation"} className="block outline w-fit outline-2 outline-green-500 hover:bg-green-500 px-8 lg:px-10 text-lg py-2 rounded-full hover:text-white mt-4 lg:mt-8 transition-all duration-300 ease">
                Get Quote
              </Link>
            </div>
            <div className="py-5">
              <img
                src={Laboratory5}
                alt="lab"
                className="w-full h-full rounded"
              />
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div className="relative z-10 grid grid-cols-1 sm:grid-cols-2 items-center justify-center gap-3 lg:gap-x-8 py-4 px-4">
            <div className="text-green-600">
              <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-gray-700">
                Experienced Factory-Trained Service Department
              </h1>
              <span className="block w-3/4 mt-3">
                With our skilled and factory-trained service department, we
                ensure that your medical equipment functions optimally,
                providing reliable support and maintenance.
              </span>

              <Link to={"request-quotation"} className="block outline w-fit outline-2 outline-green-500 hover:bg-green-500 px-8 lg:px-10 text-lg py-2 rounded-full hover:text-white mt-4 lg:mt-8 transition-all duration-300 ease">
                Get Quote
              </Link>
            </div>
            <div className="py-5">
              <img
                src={Laboratory12}
                alt="lab"
                className="w-full h-full rounded"
              />
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div className="relative z-10 grid grid-cols-1 sm:grid-cols-2 items-center justify-center gap-3 lg:gap-x-8 py-4 px-4">
            <div className="text-green-600">
              <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-gray-700">
                Commitment to Advancing Healthcare
              </h1>
              <span className="block w-3/4 mt-3">
                Driving healthcare excellence through innovation and dedication.
              </span>

              <Link to={"request-quotation"} className="block outline w-fit outline-2 outline-green-500 hover:bg-green-500 px-8 lg:px-10 text-lg py-2 rounded-full hover:text-white mt-4 lg:mt-8 transition-all duration-300 ease">
                Get Quote
              </Link>
            </div>
            <div className="py-5">
              <img
                src={Laboratory7}
                alt="lab"
                className="w-full h-full rounded"
              />
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div className="relative z-10 grid grid-cols-1 sm:grid-cols-2 items-center justify-center gap-3 lg:gap-x-8 py-4 px-4">
            <div className="text-green-600">
              <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-gray-700">
                Trusted Partner in African Healthcare
              </h1>
              <span className="block w-3/4 mt-3">
                Your dependable ally in elevating healthcare standards
                regionally.
              </span>

              <Link to={"request-quotation"} className="block outline w-fit outline-2 outline-green-500 hover:bg-green-500 px-8 lg:px-10 text-lg py-2 rounded-full hover:text-white mt-4 lg:mt-8 transition-all duration-300 ease">
                Get Quote
              </Link>
            </div>
            <div className="py-5">
              <img
                src={Laboratory13}
                alt="lab"
                className="w-full h-full rounded"
              />
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
      <div className="w-60 h-60 lg:w-[23rem] lg:h-[23rem] bg-green-100 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full"></div>
    </div>
  );
};
