import { FC } from "react";
import { BarLoader } from "react-spinners";

export const Loader: FC<{ loading: boolean }> = ({ loading }) => {
  return (
    <div
      className={`fixed z-20 h-screen w-screen top-0 left-0 bg-black/50 grid place-content-center ${
        !loading ? "hidden" : ""
      }`}
    >
      <BarLoader
        color={"#ffffff"}
        loading={true}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

