import { useDeleteMutation, useFetch } from "../../hooks";
import { LargeSpinner } from "../../components";
import { useState } from "react";
import { DeleteModal } from "../../components/modals";
import { ClientsPantnerT } from "../../types/ClientsPantner";
import SponsorCard from "../../components/cards/SponsorCard";
import { Laboratory10 } from "../../assets";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { Nodata } from "../../components/cards";

export const ClientsPartners = () => {
  const query = useFetch<ClientsPantnerT>({
    endpoint: "/clients_partners",
    cacheId: "clients_partners",
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedClientId, setSelectedBlogId] = useState<number | null>(null);

  const deleteMutation = useDeleteMutation({
    endpoint: "/clients_partners",
    cacheId: "clients_partners",
  });

  const deleteClientsPartners = () => {
    deleteMutation.mutate(selectedClientId!);
    setShowModal(false);
  };

  return (
    <div className="mt-5 w-full px-3 md:px-5 lg:px-10">
      {query.isPending || deleteMutation.isPending ? (
        <LargeSpinner />
      ) : (
        <div className="bg-white py-10">
          <h3 className="capitalize text-center text-green-600 text-2xl md:text-3xl lg:text-4xl font-bold mb-3">
            Partners / Clients
          </h3>
          {query.isPending ? (
            <LargeSpinner />
          ) : (
            <div>
              {query.isSuccess && (
                <div>
                  {query.data.length ? (
                    <div className="w-full grid gap-y-4 gap-x-3 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 sm:gap-x-3 xl:gap-x-10 xl:gap-y-6 lg:gap-x-4 px-[1.5rem] sm:px-6 xl:px-36 py-8">
                      {query.data.map((partners) => (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <SponsorCard
                            image={
                              partners.images[0]
                                ? `https://api.pacificdiagnosticsmw.com/${partners.images[0].url}`
                                : Laboratory10
                            }
                            name={partners.fullName}
                            webLink={partners.link}
                          />
                          <div className="flex items-center justify-center gap-x-2 mt-4">
                            {/* <button className="bg-green-600 text-white flex items-center gap-x-1 font-medium dark:text-green-500 hover:text-white hover:bg-green-500 rounded-lg p-2">
                            <MdOutlineEdit className="text-xl" />
                            edit
                            </button> */}
                            <button
                              onClick={() => {
                                setSelectedBlogId(partners.id);
                                setShowModal(true);
                              }}
                              className="bg-red-600 flex items-center gap-x-1 font-medium text-white dark:text-red-500 hover:text-white hover:bg-red-500 rounded-lg p-2"
                            >
                              <MdOutlineDeleteOutline className="text-xl" />
                              delete
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <Nodata />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <DeleteModal
        modalTitle="Delete Clients-Partners"
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        deleteFn={deleteClientsPartners}
      />
    </div>
  );
};
