import { TeamCard } from "./cards";
import { team3_urjit, team1_kaunda } from "../assets";

export const TeamSection = () => {
  return (
    <div className="py-6 px-3 sm:px-16 lg:px-20 bg-green-50">
      <h3 className="capitalize text-center text-green-600 text-2xl md:text-3xl lg:text-4xl xl:text-[2.5rem] font-bold">
        Meet our team
      </h3>

      <div className="w-full grid gap-y-3 sm:grid-cols-2 md:grid-cols-3 sm:gap-x-3 lg:grid-cols-4 lg:gap-x-5 px-[1.5rem] sm:px-6 py-8">
        <TeamCard
          image={team1_kaunda}
          name="Khumbo Kaunda"
          position="Country Director"
          phoneNumber="+265994394970"
          emailAddress="info@pacificdiagnosticsmw.com"
        />
        <TeamCard
          image={team3_urjit}
          name="Urjit Thakur"
          position="Customer Service Engineer"
          phoneNumber="+265880917288"
          emailAddress="urjit.thakur@pacificdiagnosticsmw.com"
        />
        {/* <TeamCard
          image={team2}
          name="Prashant Gokarn"
          position="Managing Director"
          phoneNumber="+255784220065"
        /> */}
        {/* <TeamCard
          image={team_ian}
          name="Ian kasitomu"
          position="Marketing Executive"
          phoneNumber="+265994394970"
          emailAddress="ian.kasitomu@pacificdiagnosticsmw.com"
        /> */}
      </div>
    </div>
  );
};
