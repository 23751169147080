import { FAQCard, Nodata } from "../../components/cards";
import { useDeleteMutation, useFetch } from "../../hooks";
import { FaqT } from "../../types";
import { LargeSpinner } from "../../components";
import { useState } from "react";
import { DeleteModal } from "../../components/modals";

export const FAQ = () => {
  const query = useFetch<FaqT>({ endpoint: "/f_a_qs", cacheId: "faqs" });
  const [showModal, setShowModal] = useState(false);
  const [selectedFaqId, setSelectedFaqId] = useState<number | null>(null);

  const deleteMutation = useDeleteMutation({
    endpoint: "/f_a_qs",
    cacheId: "faqs",
  });

  const deleteFaq = () => {
    deleteMutation.mutate(selectedFaqId!);
    setShowModal(false);
  };

  return (
    <div className="mt-5 w-full px-3 md:px-5 lg:px-10">
      {query.isPending || deleteMutation.isPending ? (
        <LargeSpinner />
      ) : (
        <div
         
          className="grid  grid-cols-2 sm:grid-cols-2 gap-3 md:gap-5 lg:gap-10"
        >
          {query.isSuccess && (
            <div>
              {query.data.length ? (
                <div>
                  {query.data.map((faq) => (
                    <FAQCard
                      key={faq.id}
                      faq={faq}
                      selectFn={() => {
                        setSelectedFaqId(faq.id);
                        setShowModal(true);
                      }}
                    />
                  ))}
                </div>
              ) : (
                <Nodata />
              )}
            </div>
          )}
        </div>
      )}

      <DeleteModal
        modalTitle="Delete faq"
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        deleteFn={deleteFaq}
      />
    </div>
  );
};
