import  {  FormEvent, useEffect,  } from "react";

import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { ModalContainer } from "../ModalContainer";
import { ModalContainerT,  VideosT } from "../../types";
import { useUploadMutation } from "../../hooks";
import { LargeSpinner } from "..";



export const AddVideoModel = (
  props: Pick<ModalContainerT, "showModal" | "closeModal">
) => {
  // const [videoData, setVideoData] = useState<VideosT>({
  //   title: "",
  //   description: "",
  //   videos: [],
  //   videoable_id: 0,
  // });

  const mutation = useUploadMutation<VideosT>({
    cacheId: "videos",
    endpoint: "/videos",
  });

  const handleUploadBlog = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // mutation.mutate({
    //   title: videoData.title,
    //   description: videoData.description,
    //   videos: videoData.videos,
    //   videoable_id: videoData.videoable_id,
    // });
  };

  // const videoDataChange = (
  //   event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  // ) => {
  //   const { name, value } = event.target;

  //   setVideoData((prevData) => ({ ...prevData, [name]: value }));
  // };

  // const handleDescriptionChange = (value) => {
  //   setVideoData((prevData) => ({ ...prevData, description: value }));
  // };

  useEffect(() => {
    if (mutation.isSuccess) {
      props.closeModal();
      location.reload();
    }
    
    
  }, [mutation, props]);
  
  return (
    <ModalContainer
      modalTitle="Add Videos"
      showModal={props.showModal}
      closeModal={props.closeModal}
    >
      {mutation.isPending ? (
        <LargeSpinner />
      ) : mutation.isSuccess ? (
        <h3>Videos posted </h3>
      ) : null}
      <form
        style={{ width: "1200px" }}
        className="py-3 px-5"
        onSubmit={handleUploadBlog}
      >
        <div className="mb-4 w-full">
          <label
            htmlFor="title"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Title
          </label>

          <input
            type="text"
            id="title"
            name="title"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
            placeholder="title"
            // value={videoData.title}
            // onChange={videoDataChange}
          />
        </div>

        {/* <div className="mb-4 w-full">
          <label
            htmlFor="sub-title"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Sub Title
          </label>

          <input
            type="text"
            id="sub-title"
            name="sub_title"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
            placeholder="sub-title"
            value={videoData.sub_title}
            onChange={videoDataChange}
          />
        </div> */}

        <div className="mb-4 w-full">
          <label
            htmlFor="description"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Description
          </label>

          {/* <QuillEditor onDescriptionChange={handleDescriptionChange} /> */}
        </div>

        <div className="mb-4 w-full">
          <label
            htmlFor="project"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Project
          </label>

          <select
            id="project"
            name="videoable_id"
            typeof="number"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
            // onChange={videoDataChange}
            // value={videoData.videoable_id || "default"}
          >
            <option value={"default"} disabled>
              __select Prpject__
            </option>
            <option value="1">Completed</option>
            <option value="1">In Progress</option>
          </select>
        </div>

        <div className="mb-4 w-full">
          <label
            htmlFor="videos"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Videos
          </label>

          <input
            type="file"
            id="Videos"
            className="bg-gray-50 text-sm rounded block w-full"
            multiple
            accept="video/*"
            // onChange={(event) =>
              // setVideoData((prevData) => ({
              //   ...prevData,
              //   videos : event.target.files,
              // }))
            // }
          />
        </div>

        <div>
          <button className="w-full bg-primary py-2 rounded text-white tracking-wider">
            Upload Videos
          </button>
        </div>
      </form>
    </ModalContainer>
  );
};
