import { ChangeEvent, FormEvent, useState } from "react";
import { QuoteT } from "../../types";
import { useUploadMutation } from "../../hooks";

export const RequestQuote = () => {
  const [quoteData, setQuoteData] = useState<QuoteT>({
    contact_information: "",
    budget: 0,
    quantity: 0,
    facility_details: "",
    additional_details: "",
    equipment_type: "",
    id: 0,
    updated_at: ""
  });

  const { mutate, isPending,  isSuccess } = useUploadMutation<QuoteT>({
    endpoint: "/quotes",
    cacheId: "quotes",
  });

  const handleUploadQuote = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    mutate({
      additional_details: quoteData.additional_details,
      budget: quoteData.budget,
      contact_information: quoteData.contact_information,
      equipment_type: quoteData.equipment_type,
      facility_details: quoteData.facility_details,
      quantity: quoteData.quantity,
      id: 0,
      updated_at: ""
    });
  };

  const quoteDataChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    setQuoteData((prevData) => ({ ...prevData, [name]: value }));
  };
  return (
    <div
      style={{ maxWidth: "1000px", margin: "auto", marginTop: "4rem" }}
      className="xl:px-20 mb-4 2xl:px-32, p-6 border border-gray-200"
    >
      {!isSuccess ? (
        <div>
          <div className="block w-full pb-6  bg-white  rounded-lg  dark:bg-gray-800 ">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Request a Quote
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              Thank you for considering Pacific Diagnostic Ltd for your medical
              equipment needs. Please fill out the form below, specifying your
              requirements in the form
            </p>
          </div>

          <form onSubmit={handleUploadQuote}>
            <div className="grid gap-6 mb-6 md:grid-cols-2">
              <div>
                <label
                  id="contact_information"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Contact Information
                </label>
                <input
                  type="text"
                  id="contact_information"
                  name="contact_information"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Name, Email, Phone"
                  required
                  onChange={quoteDataChange}
                />
              </div>
              <div>
                <label
                  id="facility_details"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Facility Details
                </label>
                <input
                  type="text"
                  id="facility_details"
                  name="facility_details"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Name, Location"
                  required
                  onChange={quoteDataChange}
                />
              </div>

              <div>
                <label
                  id="equipment_type"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Equipment Type
                </label>
                <input
                  type="tel"
                  id="equipment_type"
                  name="equipment_type"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Specify the type of medical equipment or consumables you require."
                  required
                  onChange={quoteDataChange}
                />
              </div>

              <div>
                <label
                  id="quantity"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Quantity
                </label>
                <input
                  type="number"
                  id="quantity"
                  name="quantity"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Indicate the quantity needed."
                  required
                  onChange={quoteDataChange}
                />
              </div>
            </div>

            <div className="mb-6">
              <label
                id="budget"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Budget: Your estimated budget, if available.
              </label>
              <input
                type="number"
                id="budget"
                name="budget"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="$66,584,489"
                required
                onChange={quoteDataChange}
              />
            </div>

            <div>
              <label
                id="additional_details"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Additional Details
              </label>
              <textarea
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary resize-none"
                id="additional_details"
                name="additional_details"
                placeholder="Any specific features or preferences."
                cols={20}
                rows={7}
                onChange={quoteDataChange}
              />
            </div>
            <button
              type="submit"
              disabled={isPending}
              className="mt-5 text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {isPending ? "Loading..." : "Submit"}
            </button>
          </form>
        </div>
      ) : (
        <div className="block m-20 w-full pb-6  bg-white  rounded-lg  dark:bg-gray-800 ">
          <h2>Thank You!</h2>
          <p>Your quotation has been successfully submitted.</p>
          <p>
            We appreciate your interest, and our team will get back to you as
            soon as possible.
          </p>
        </div>
      )}
    </div>
  );
};
