import { Link } from "react-router-dom";
import { MdOutlineLogout, MdProductionQuantityLimits } from "react-icons/md";
import { FaQuestionCircle } from "react-icons/fa";
import { RiProjectorFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
export const AdminSidebar = () => {
  
  return (
    <aside
      id="logo-sidebar"
      className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
      aria-label="Sidebar"
    >
      <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
        <ul className="space-y-2 font-medium">
          <li>
            <Link
              to="/admin"
              className="flex items-center p-1 text-gray-500 hover:text-green-500 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <svg
                className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-green-500 dark:group-hover:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 18"
              >
                <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
              </svg>
              <span className="ml-3">Dashboard</span>
            </Link>
          </li>

          <li>
            <Link
              to="/admin/products"
              className="flex items-center p-1 text-gray-500 hover:text-green-500 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <MdProductionQuantityLimits className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-green-500 dark:group-hover:text-white" />
              <span className="flex-1 ml-3 whitespace-nowrap">Products</span>
            </Link>
          </li>

          <li>
            <Link
              to="/admin/projects"
              className="flex items-center p-1 text-gray-500 hover:text-green-500 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <RiProjectorFill className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-green-500 dark:group-hover:text-white" />
              <span className="flex-1 ml-3 whitespace-nowrap">Projects</span>
            </Link>
          </li>

          <li>
            <Link
              to="/admin/services"
              className="flex items-center p-1 text-gray-500 hover:text-green-500 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <RiProjectorFill className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-green-500 dark:group-hover:text-white" />
              <span className="flex-1 ml-3 whitespace-nowrap">Services</span>
            </Link>
          </li>

          <li>
            <Link
              to="/admin/blog"
              className="flex items-center p-1 text-gray-500 hover:text-green-500 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <RiProjectorFill className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-green-500 dark:group-hover:text-white" />
              <span className="flex-1 ml-3 whitespace-nowrap">Blog</span>
            </Link>
          </li>

          <li>
            <Link
              to="/admin/requestquote"
              className="flex items-center p-1 text-gray-500 hover:text-green-500 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <RiProjectorFill className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-green-500 dark:group-hover:text-white" />
              <span className="flex-1 ml-3 whitespace-nowrap">Quotation</span>
            </Link>
          </li>
          <li>
            <Link
              to="/admin/clients-partner"
              className="flex items-center p-1 text-gray-500 hover:text-green-500 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <RiProjectorFill className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-green-500 dark:group-hover:text-white" />
              <span className="flex-1 ml-3 whitespace-nowrap">
                Clients-Partners
              </span>
            </Link>
          </li>
          <li>
            <Link
              to="/admin/testimonial"
              className="flex items-center p-1 text-gray-500 hover:text-green-500 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <RiProjectorFill className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-green-500 dark:group-hover:text-white" />
              <span className="flex-1 ml-3 whitespace-nowrap">Testimonial</span>
            </Link>
          </li>
          <li>
            <Link
              to="/admin/about-us"
              className="flex items-center p-1 text-gray-500 hover:text-green-500 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <RiProjectorFill className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-green-500 dark:group-hover:text-white" />
              <span className="flex-1 ml-3 whitespace-nowrap">About Us</span>
            </Link>
          </li>
          <li>
            <Link
              to="/admin/faq"
              className="flex items-center p-1 text-gray-500 hover:text-green-500 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <FaQuestionCircle className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-green-500 dark:group-hover:text-white" />
              <span className="flex-1 ml-3 whitespace-nowrap">FAQ</span>
            </Link>
          </li>

          <li className="pt-8">
            <Link
              to=""
              onClick={logout}
              className="flex items-center p-1 text-gray-500 hover:text-green-500 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <MdOutlineLogout className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-green-500 dark:group-hover:text-white" />
              <span className="flex-1 ml-3 whitespace-nowrap">Sign out</span>
            </Link>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export const logout = () => {
  localStorage.removeItem("pacific-access-token");

  // Navigate the user to the login page
  const navigate = useNavigate();
  navigate("/auth/login");
};