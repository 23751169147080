import { AssistantAccountant, BIOMEDICAL_ENGiNEER } from "../../assets/pdf"

export const Vacancies = () => {
  return (
    <div style={{justifyContent:"center", alignContent:'center', padding:"19px", display: "flex", flexDirection:"column", width:"100%"}} >
        <div style={{display:"flex",margin: "auto" }}>
            <h1  className="px-6 mb-7 text-2xl w-full sm:text-2xl md:text-3xl lg:text-4xl font-bold">
            EMPLOYMENT<span className="text-green-400"> OPPORTUNITY</span></h1>
        </div>
        <div style={{maxWidth: "800px", margin: "auto"}} className="w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
        <h5  className="mb-4 text-xl capitalize font-medium text-gray-500 dark:text-gray-400">POST:  Assistant Accountant</h5>
        <p>An opportunity has risen to join our team as an Assistant Accountant at Pacific Diagnostics Ltd.
          The company is a distributor of medical equipment, accessories and consumables located in 
          Lilongwe, Malawi.
          </p>
          <h5  className="mt-4 text-xl capitalize font-medium text-gray-500 dark:text-gray-400">DUTIES AND RESPONSIBILITIES OF ASSISTANT ACCOUNTANT</h5>
        <h5 className="mt-4 font-medium text-gray-500 dark:text-gray-400">General Ledger Management</h5>
        <ul role="list" className="space-y-5 my-7">
        <li className="flex items-center">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">Posting of the cash book in the general Ledger</span>
        </li>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">Posting payroll in the account system</span>
        </li>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">Capturing creditors and receivable invoices in the system
        </span>
        </li>
        <p>Reconciliation of the following</p>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
        Cash book with the bank 
        </span>
        </li>
        
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
        Creditors account </span>
        </li>
        
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
        Debtors accounts
    </span>
        </li>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
        Inventory account</span>
        </li>
        <p>Document Filling</p>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
        Payment vouches and ensure they have supporting documents</span>
        </li>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
        Filling tax retains and other statutory deductions after submission
      </span>
        </li>
        <p>Maintaining fixed Asset Register</p>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
        Tracking asset movements both physical and in the FAR.</span>
        </li>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
        Ensure that the Asset Register is up to date.</span>
        </li>
        <p>Treasury Management</p>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
        Conducting bank deposits and withdraws</span>
        </li>
        <li className="flex">
        
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
        Any other duties as assigned by management related to the accounts assistant work.
      </span>
        </li>
        <p>Requirements and Skills</p>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
       
        Minimum of a Degree in accounting. Those with Diploma and a relevant experience may 
be considered.</span>
        </li>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
       
        Working knowledge of Sage evolution accounting package.</span>
        </li>
        
        <li className="flex">
        
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
        
        Interested applicants must send their applications together with curriculum vita and copies of 
certificates to info@pacificdiagnosticsmw.com</span>
        </li>
        
        
        </ul>
        <div className="flex">
        <a  style={{maxWidth:"150px", marginRight:".4rem"}} className="text-white bg-green-400 hover:bg-green-500 focus:ring-4 focus:outline-none focus:ring-green-200 dark:bg-green-400 dark:hover:bg-green-700 dark:focus:ring-green-600 font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center" href={AssistantAccountant} download={`Assistant Accountant at Pacific Diagnostics Ltd`}>Download pdf</a>
        <a href="mailto:info@pacificdiagnosticsmw.com">
        <button type="button" className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-200 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900 font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center">Apply Now </button>
        </a>
        </div>
        </div>
        <div style={{maxWidth: "800px", margin: "1rem auto"}} className="w-full mt-4  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
        <h5 className="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">POST: BIOMEDICAL ENGINEER</h5>
        
        <ul role="list" className="space-y-5 my-7">
        <li className="flex items-center">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">Holder of degree in biomedical engineering from a recognized institution</span>
        </li>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">Must be aged between 25 and 35 years</span>
        </li>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">Capable of working on Radiology Machines with minimum supervision
        </span>
        </li>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
        Proven work experience on Siemens magnetic Resonance Imaging systems both closed helium 
        system and that of quench pipe.
        </span>
        </li>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
        Demonstrate the understanding of the key stages in installation process of Computerized 
        tomography machine and Magnetic Resonance Imaging systems</span>
        </li>
        <li className="flex">
        
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
        As an Engineer he/she must take a Lead in Installation and routine maintenance work at the client/customer sites. He/she may work remotely depending on the availability of necessary technology but at all cost he/she must be in a position to resolve a technical issue that arises at site. As well as do the following:
        </span>
        </li>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
        Delivering services to customers effectively and on time;
    </span>
        </li>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
        Monitoring and maintaining electrical equipment or machinery performance;</span>
        </li>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
        Walking customers through on how equipment works including how to use it safely;</span>
        </li>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
        Explaining new features and suggesting upgrades to customers;</span>
        </li>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
        Participating in ongoing training to keep up to date with the latest developments in the field of engineering;</span>
        </li>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
       
        working with manufacturers of devices and their marketing teams</span>
        </li>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
       
        Promoting and Marketing company product and services to potential Client</span>
        </li>
        <li className="flex">
        <svg className="flex-shrink-0 w-4 h-4 text-green-700 dark:text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
        
            writing reports and documentation of work done at client site</span>
        </li>
        <li className="flex">
        
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
        
        For those interested and qualified please send your copies of certificates and resumes to 
        info@pacificdiagnosticsmw.com. Closing date is 8th March 2024</span>
        </li>
        
        
        </ul>
        <div className="flex">
        <a  style={{maxWidth:"150px", marginRight:".4rem"}} className="text-white bg-green-400 hover:bg-green-500 focus:ring-4 focus:outline-none focus:ring-green-200 dark:bg-green-400 dark:hover:bg-green-700 dark:focus:ring-green-600 font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center" href={BIOMEDICAL_ENGiNEER} download={`${BIOMEDICAL_ENGiNEER}`}>Download pdf</a>
        <a href="mailto:info@pacificdiagnosticsmw.com">
        <button type="button" className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-200 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900 font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center">Apply Now </button>
        </a>
        </div>
        </div>

    </div>
  )
}
