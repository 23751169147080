import { useDeleteMutation, useFetch } from "../../hooks";
import { LargeSpinner } from "../../components";
import { useState } from "react";
import { DeleteModal } from "../../components/modals";
import {  Nodata, TestimonialCard } from "../../components/cards";
import { TestimonialT } from "../../types";

export const Testimonials = () => {
  const [showModal, setShowModal] = useState(false);
  const query = useFetch<TestimonialT>({
    endpoint: "/testimonials",
    cacheId: "testimonials",
  });

  const [selectedTestimonialId, setSelectedTestimonialId] = useState<
    number | null
  >(null);

  const deleteMutation = useDeleteMutation({
    endpoint: "/testimonials",
    cacheId: "testmonials",
  });

  const deleteTestmonial = () => {
    deleteMutation.mutate(selectedTestimonialId!);
    setShowModal(false);
  };

  return (
    <div className="bg-white">
      {query.isPending || deleteMutation.isPending ? (
        <LargeSpinner />
      ) : (
        <div>
          {query.isSuccess && (
            <div>
              {query.data.length ? (
                <div>
                  {query.data.map((testimonial, index: number) => (
                    <TestimonialCard
                      key={index}
                      testmonial={testimonial}
                      targetUser="admin"
                      selectFn={() => {
                        setSelectedTestimonialId(testimonial.id);
                        setShowModal(true);
                      }}
                    />
                  ))}
                </div>
              ) : (
                <Nodata />
              )}
            </div>
          )}
        </div>
      )}

      <DeleteModal
        modalTitle="Delete testimonial"
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        deleteFn={deleteTestmonial}
      />
    </div>
  );
};


