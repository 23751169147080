// import { useState } from "react";

export const AdminHome = () => {
  // const [files, ] = useState<FileList | null>(null);
  // const [ setIsLoading] = useState(false);

  // const submitImages = async () => {
  //   try {
  //     setIsLoading(true);
  //     const { data } = await AxiosApi.postForm("/blog", {
  //       images: files,
  //       title: "Introducing our Thearted 3.098",
  //       description: "This is one of the best we have",
  //       type: "Products",
  //     });

    //   setIsLoading(false);

    //   console.log(data);
    // } catch (error) {
    //   console.error(error);
    // }
  // };
  return (
    <div className="w-full p-6 bg-white text-left border border-green-200 rounded-lg shadow dark:bg-green-800 dark:border-green-700">
      <a href="#">
        <h5 className="mb-2 text-2xl text-left font-bold tracking-tight text-green-900 dark:text-white">
          Welcome to the Pacific Website Management Dashboard
        </h5>
      </a>
      <p className="mb-3 text-left font-normal text-green-700 dark:text-green-400">
        You've entered the command center for Pacific's online presence! Here,
        you can effortlessly manage and update a range of essential elements,
        including partners, clients, testimonials, blogs, projects, products,
        and services. For the best experience, we recommend using a computer
        rather than a phone.
      </p>
      <h5>Here's a quick guide to get you started:</h5>
      <p className="mb-3 text-left font-normal text-green-700 dark:text-green-400">
        <strong>Content Sections:</strong> On the left-hand side of the
        dashboard, you'll find categorized sections for all the elements you can
        manage—partners, clients, testimonials, blogs, projects, products, and
        services. Select a section to view and manage its respective content.
      </p>
      <p className="mb-3 text-left font-normal text-green-700 dark:text-green-400">
        <strong>Adding New Content:</strong> At the bottom, you'll notice an
        "Upload Content" button. Click it to open a tab where you can
        conveniently add new partners, clients, testimonials, blog posts,
        projects, products, and services.
      </p>
      <p className="mb-3 text-left font-normal text-green-700 dark:text-green-400">
        If you encounter any difficulties or require assistance, please don't
        hesitate to reach out to us at admin@pacificdiagnosticsmw.com or call Admin
        at +265993585213.
      </p>
      <p className="mb-3 text-left font-normal text-green-700 dark:text-green-400">
        Thank you for being a vital part of Pacific's online presence and
        helping us keep our digital footprint up-to-date!
      </p>
    </div>
  );
};
