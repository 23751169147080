import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { ModalContainer } from "../ModalContainer";
import { GalleryData, ModalContainerT } from "../../types";
import { useUploadMutation } from "../../hooks";
import { LargeSpinner } from "../LargeSpinner";

export const AddGalleryModal = (
  props: Pick<ModalContainerT, "showModal" | "closeModal">
) => {
  const [projectData, setGalleryData] = useState<GalleryData>({
    images: null,
    type: "",
  });

  const mutation = useUploadMutation<GalleryData>({
    cacheId: "gallery",
    endpoint: "/gallery",
  });

  const handleGalleryBlog = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    mutation.mutate({
      type: projectData.type,
      images: projectData.images,
    });
  };

  const galleryDataChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    setGalleryData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      props.closeModal();
      location.reload();
    }
  }, [mutation, props]);

  return (
    <ModalContainer
      modalTitle="Add Gallery"
      showModal={props.showModal}
      closeModal={props.closeModal}
    >
      {mutation.isPending ? (
        <LargeSpinner />
      ) : mutation.isSuccess ? (
        <h3>Blog posted </h3>
      ) : null}
      <form className="py-3 px-5" onSubmit={handleGalleryBlog}>
        <div className="mb-4 sm:w-[300px] lg:w-[350px]">
          <label
            htmlFor="type"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Category
          </label>

          <select
            id="type"
            name="type"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
            onChange={galleryDataChange}
            value={projectData.type || "default"}
          >
            <option value={"default"} disabled>
              __select category__
            </option>
            <option value="labolatory">Labolatory</option>
            <option value="radiology">Radiology</option>
          </select>
        </div>

        <div className="mb-4 sm:w-[300px] lg:w-[350px]">
          <label
            htmlFor="images"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Images
          </label>

          <input
            type="file"
            id="images"
            className="bg-gray-50 text-sm rounded block w-full"
            multiple
            accept="image/*"
            onChange={(event) =>
              setGalleryData((prevData) => ({
                ...prevData,
                images: event.target.files,
              }))
            }
          />
        </div>

        <div>
          <button className="w-full bg-primary py-2 rounded text-white tracking-wider">
            Upload Gallery
          </button>
        </div>
      </form>
    </ModalContainer>
  );
};
