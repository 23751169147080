import { ChangeEvent, FormEvent, useState } from "react";
import { TestimonialData } from "../types";
import { useUploadMutation } from "../hooks";

export const CustomerTestimonial = () => {
  const [testimonialData, setTestimonialData] = useState<TestimonialData>({
    fullName: "",
    position: "",
    content: "",
    images: null,
  });

  const { mutate, isPending, isSuccess } = useUploadMutation<TestimonialData>({
    endpoint: "/testimonials",
    cacheId: "testmonials",
  });

  const testimonialDataChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    setTestimonialData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleUploadTestimonial = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    mutate({
      fullName: testimonialData.fullName,
      position: testimonialData.position,
      content: testimonialData.content,
      images: testimonialData.images,
    });
  };
  return (
    <div className="grid h-screen place-content-center px-4">
      {!isSuccess ? (
        <form
          className="py-9 px-5 rounded border-[1px] border-gray-300"
          onSubmit={handleUploadTestimonial}
        >
          <div className="mb-4 sm:w-[300px] lg:w-[350px]">
            <label
              htmlFor="fullname"
              className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
            >
              FullName
            </label>

            <input
              type="text"
              id="fullname"
              name="fullName"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
              placeholder="Your fullname"
              value={testimonialData.fullName}
              onChange={testimonialDataChange}
            />
          </div>

          <div className="mb-4 sm:w-[300px] lg:w-[350px]">
            <label
              htmlFor="position"
              className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
            >
              Company Position
            </label>

            <input
              type="text"
              id="position"
              name="position"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
              placeholder="Your company's position"
              value={testimonialData.position}
              onChange={testimonialDataChange}
            />
          </div>

          <div className="mb-4 sm:w-[300px] lg:w-[350px]">
            <label
              htmlFor="content"
              className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
            >
              Testimonial Message
            </label>

            <input
              type="text"
              id="content"
              name="content"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
              placeholder="Your testimonial"
              value={testimonialData.content}
              onChange={testimonialDataChange}
              maxLength={250}
            />
          </div>

          <div className="mb-4 sm:w-[300px] lg:w-[350px]">
            <label
              htmlFor="images"
              className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
            >
              Your Photo
            </label>

            <input
              type="file"
              id="images"
              className="bg-gray-50 text-sm rounded block w-full"
              accept="image/*"
              onChange={(event) =>
                setTestimonialData((prevData) => ({
                  ...prevData,
                  images: event.target.files,
                }))
              }
            />
          </div>

          <div>
            <button
              disabled={isPending}
              className="w-full bg-primary py-2 rounded text-white tracking-wider"
            >
              {isPending ? (
                <span>Uploading...</span>
              ) : (
                <span>Submit Testimonial</span>
              )}
            </button>
          </div>
        </form>
      ) : (
        <div className="bg-green-100 p-8 text-center rounded">
          <h3>Thank you for your feedback!!! {testimonialData.fullName}</h3>
        </div>
      )}
    </div>
  );
};
