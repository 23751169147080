import { LargeSpinner } from "../../components";
import { FAQClientCard, Nodata } from "../../components/cards";
import { FAQClient } from "../../components/cards/FAQClient";
import { useFetch } from "../../hooks";
import { FaqT } from "../../types";

export const FAQs = () => {
  const query = useFetch<FaqT>({ endpoint: "/f_a_qs", cacheId: "faqs" });

  return (
    <div
      style={{ maxWidth: "1000px", margin: "auto", marginTop: "4rem" }}
      className="xl:px-20 2xl:px-32, p-6 border border-gray-200"
    >
      <div className="block w-full pb-6  bg-white  rounded-lg  dark:bg-gray-800 ">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          Frequently Asked question
        </h5>
        <p className="font-normal text-gray-700 dark:text-gray-400">
          We are committed to delivering value to our customers and ensuring
          that their medical equipment investments continue to serve them
          effectively.
        </p>
      </div>

      {/* //   <FAQClient
    //     quastion="What is Pacific Diagnostic Ltd's primary focus?"
    //     answer="Pacific Diagnostic Ltd is committed to supplying high-quality and technologically advanced medical appliances, instruments, equipment, and consumables to healthcare providers in Tanzania, Uganda, and Kenya. Our primary mission is to provide tailored healthcare solutions with professional service support, including factory-trained engineers for after-sales service."
    //   />
    //   <FAQClient
    //     quastion="How does Pacific Diagnostic Ltd ensure the reliability and quality of its products?"
    //     answer="We strive relentlessly to achieve the highest possible reliability and quality in our products. Our goal is to set the industry standard for comparison by maintaining a strong dedication to honesty, integrity, and service."
    //   /> */}
      {/* <FAQClient
        quastion="What areas does Pacific Diagnostic Ltd specialize in?"
        answer="Our expertise lies in the supply, installation, service, and training on medical equipment and accessories. We focus on areas where we have maximum strength and ability, continuously improving through education and knowledge assimilation."
      />

      <FAQClient
        quastion="How does Pacific Diagnostic Ltd contribute to improving patient care?"
        answer="We assist the medical profession in enhancing patient diagnosis and treatment by providing durable and high-quality medical products. Our aim is to support healthcare providers in improving patients' lives through effective medical equipment."
      />
      <FAQClient
        quastion="What is Pacific Diagnostic Ltd's approach to responsible business citizenship?"
        answer="We recognize the importance of investing in the maintenance and upkeep of high-end medical equipment. Neglecting this aspect can lead to the abandonment of projects and equipment, affecting the quality of care. We believe in a holistic approach to medical equipment investment, including long-term support, maintenance, and staff training to protect these valuable investments."
      />
      <FAQClient
        quastion="About Pacific Diagnostic Ltd"
        answer="Pacific Diagnostic Ltd is a leading distributor of medical equipment, accessories, and consumables in East Africa. Our specialization includes medical imaging and surgery equipment. We have built a substantial installed base of medical equipment in East Africa, supported by an experienced factory-trained service department.

From the outset, our leadership team recognized the challenges posed by the cycle of technological decay in the industry. To address this, we have implemented a comprehensive approach that includes sophisticated sales and installation processes, staff training, and after-sales service. While this may entail higher upfront costs, it results in more timely service and reduces the overall cost of ownership over the long term. Our approach safeguards the investments in medical equipment, typically expected to have a life cycle of 8 to 10 years."
      /> */}

      {query.isPending ? (
        <LargeSpinner />
      ) : (
        <div>
          {query.isSuccess && (
            <div>
              {query.data.length ? (
                <div>
                  {query.data.map((faq) => (
                    <FAQClientCard
                      key={faq.id}
                      quastion={faq.question}
                      answer={faq.answer}
                    />
                  ))}
                </div>
              ) : (
                <>
                  <Nodata />
                  <FAQClient
                    quastion="About Pacific Diagnostic Ltd"
                    answer="Pacific Diagnostic Ltd is a leading distributor of medical equipment, accessories, and consumables in East Africa. Our specialization includes medical imaging and surgery equipment. We have built a substantial installed base of medical equipment in East Africa, supported by an experienced factory-trained service department.

From the outset, our leadership team recognized the challenges posed by the cycle of technological decay in the industry. To address this, we have implemented a comprehensive approach that includes sophisticated sales and installation processes, staff training, and after-sales service. While this may entail higher upfront costs, it results in more timely service and reduces the overall cost of ownership over the long term. Our approach safeguards the investments in medical equipment, typically expected to have a life cycle of 8 to 10 years."
                  />
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
