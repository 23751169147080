import axios from "axios";
const envFile = import.meta.env;

const AxiosApi = axios.create({
  baseURL:  
      envFile.VITE_AXIOS_BASEURL_PROD
  //     envFile.DEV
  // ? envFile.VITE_AXIOS_BASEURL_DEV
  // :envFile.VITE_AXIOS_BASEURL_PROD
});

  
  AxiosApi.interceptors.response.use(
      (response) => response,
      (error) => Promise.reject(error.response.data)
    );

export { AxiosApi };
