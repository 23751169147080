import { Link } from "react-router-dom";

type props = {
  title: string;
  quantity: number;
  image: string;
  price: number;
  id:number;
  availability: string;
};
export const ProductClient = ({
  title,
  quantity,
  id,
  availability,
  image,
  price,
}: props) => {
  return (
    <Link
      to={"/product/details/" + id}
      className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
    >
      <a href="" style={{overflow:"hidden", height:"250px", display: "flex", justifyContent:"center", alignItems:"center"}} className="block p-3 max-h-400">
        <img className="rounded border " src={image} alt={title} />
      </a>
      <div className="px-3 pb-5">
        <a href="">
          <h5 style={{fontSize:"15px"}} className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
            {title}
          </h5>
        </a>
        <div className="flex items-center mt-2.5 mb-5">
          <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-0 py-0.5 rounded dark:bg-green-200 dark:text-green-800">
            Quantity: {quantity}
          </span>
          <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-800 ml-3">
            {availability}
          </span>
          <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-800 ml-3">
            {price == 0 ? "" : "Price: $" + price}
          </span>
        </div>
        <div className="flex items-center w-full justify-between">
          <a
            href=""
            className="text-white w-full bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            Check Product
          </a>
        </div>
      </div>
    </Link>
  );
};
