import { useLocation, Outlet } from "react-router-dom";
import { useLayoutEffect } from "react";

export const MainRoot = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [location.pathname]);



  return (
    <div>
      <Outlet />
    </div>
  );
};
