import {  Nodata } from "../../components/cards";
import { useDeleteMutation, useFetch } from "../../hooks";
import { LargeSpinner } from "../../components";
import { useState } from "react";
import { DeleteModal } from "../../components/modals";
import { AboutT } from "../../types/AboutT";
import { MdOutlineDeleteOutline } from "react-icons/md";

export const AdminAboutUs = () => {
  const query = useFetch<AboutT>({ endpoint: "/about", cacheId: "about" });
  const [showModal, setShowModal] = useState(false);
  const [selectedAboutId, setSelectedAboutId] = useState<number | null>(null);

  const deleteMutation = useDeleteMutation({
    endpoint: "/about",
    cacheId: "about",
  });

  const deleteAbout = () => {
    deleteMutation.mutate(selectedAboutId!);
    setShowModal(false);
  };

  return (
    <div className="mt-5 mb-10 w-full px-3 md:px-5 lg:px-10">
      {query.isPending || deleteMutation.isPending ? (
        <LargeSpinner />
      ) : (
        <div >
          {query.isSuccess && (
            <div>
              {query.data.length ? (
                <div>
                  {query.data.map((about) => (
                    <div>
                      <h1 className="text-green-600 font-bold py-2 border border-green-600 text-center mt-1 mb-1">
                        {" "}
                        {about.title}
                      </h1>
                      <div
                        dangerouslySetInnerHTML={{ __html: about.description }}
                      />
                      <div className="flex items-center justify-center gap-x-2 mt-4">
                        {/* <button className="bg-green-600 text-white flex items-center gap-x-1 font-medium dark:text-green-500 hover:text-white hover:bg-green-500 rounded-lg p-2">
                            <MdOutlineEdit className="text-xl" />
                            edit
                            </button> */}
                        <button
                          onClick={() => {
                            setSelectedAboutId(about.id);
                            setShowModal(true);
                          }}
                          className="bg-red-600 flex items-center gap-x-1 font-medium text-white dark:text-red-500 hover:text-white hover:bg-red-500 rounded-lg p-2"
                        >
                          <MdOutlineDeleteOutline className="text-xl" />
                          delete
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <Nodata />
              )}
            </div>
          )}
        </div>
      )}

      <DeleteModal
        modalTitle="Delete About Us"
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        deleteFn={deleteAbout}
      />
    </div>
  );
};
