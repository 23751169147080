import { createBrowserRouter } from "react-router-dom";
import RouteNotFound from "./pages/RouteNotFound";
import {
  MainRoot,
  AdminRoot,
  AdminHome,
  ClientRoot,
  LandingPage,
  Adminlogin,
  AdminSetPassword,
  AdminForgotPassword,
  AboutCompany,
  AboutOffices,
  AboutProjects,
  Contact,
  AboutRoot,
  Services,
  ProjectDetails,
  ProjectsRoot,
  Products,
  TeanInfo,
  FAQ,
  AdminProjects,
  Blog,
  Testimonials,
  RequestQouteAdmin,
  Gallery,
  Blogpage,
  CustomerTestimonial,
  Companies,
  FAQs,
  ProductsPage,
  ProductDetails,
  AdminServices,
  ClientsPartners,
} from "./pages";
import {  BlogDetailsCard, Nodata } from "./components/cards";
import { RequestQuote } from "./pages/client/RequestQoute";
import { BlogRoot } from "./pages/client/BogRoot";
import { ProductsRoot } from "./pages/client/ProductsRoot";
import { AdminAboutUs } from "./pages/admin/AdminAboutUs";
import { Vacancies } from "./pages/client/Vacancies";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainRoot />,
    errorElement: <RouteNotFound />,
    children: [
      {
        path: "/",
        element: <ClientRoot />,
        children: [
          {
            index: true,
            element: <LandingPage />,
          },
          {
            path: "about",
            element: <AboutRoot />,
            children: [
              {
                path: "company",
                element: <AboutCompany />,
              },
              {
                path: "offices",
                element: <AboutOffices />,
              },
              {
                path: "projects",
                element: <ProjectsRoot />,
                children: [
                  {
                    index: true,
                    element: <AboutProjects />,
                  },
                  {
                    path: "details/:projectId",
                    element: <ProjectDetails />,
                  },
                ],
              },
              {
                path: "companies",
                element: <Companies />,
              },{
                path: "vacancies",
                element: <Vacancies />,
              },
            ],
          },
          {
            path: "services",
            element: <Services />,
          },
          {
            path: "contact",
            element: <Contact />,
          },
          {
            path: "frequently-asked-question",
            element: <FAQs />,
          },
          {
            path: "product",
            element: <ProductsRoot />,
            children: [
              {
                index: true,
                element: <ProductsPage />,
              },
              {
                path: "details/:producId",
                element: <ProductDetails />,
              },
            ],
          },
          {
            path: "gallery",
            element: <Gallery />,
          },
          {
            path: "blog",
            element: <BlogRoot />,
            children: [
              {
                index: true,
                element: <Blogpage />,
              },
              {
                path: "details/:id",
                element: <BlogDetailsCard />,
              },
            ],
          },
          {
            path: "nodata",
            element: <Nodata />,
          },
          {
            path: "about/team-details",
            element: <TeanInfo />,
          },
          {
            path: "request-quotation",
            element: <RequestQuote />,
          },
        ],
      },
      {
        path: "/admin",
        element: <AdminRoot />,
        children: [
          {
            index: true,
            element: <AdminHome />,
          },
          {
            path: "about-us",
            element: <AdminAboutUs />,
          },
          {
            path: "products",
            element: <Products />,
          },
          {
            path: "clients-partner",
            element: <ClientsPartners />,
          },
          {
            path: "faq",
            element: <FAQ />,
          },
          {
            path: "projects",
            element: <AdminProjects />,
          },
          {
            path: "blog",
            element: <Blog />,
          },
          {
            path: "testimonial",
            element: <Testimonials />,
          },
          {
            path: "requestquote",
            element: <RequestQouteAdmin />,
          },
          {
            path: "services",
            element: <AdminServices />,
          },
        ],
      },
      {
        path: "/auth/login",
        element: <Adminlogin />,
      },
      {
        path: "/auth/set-password",
        element: <AdminSetPassword />,
      },
      {
        path: "/auth/forgot-password",
        element: <AdminForgotPassword />,
      },
    ],
  },
  {
    path: "/testimonial",
    element: <CustomerTestimonial />,
  },
]);

export default router;
