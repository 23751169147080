import { kenyaFlag, malawiFlag, tanzaniaFlag, ugandaFlag } from "../../assets";
import { OfficeCard } from "../../components/cards";

export const AboutOffices = () => {
  return (
    <div className="px-3 sm:px-16 lg:px-32 bg-white py-3 lg:py-14">
      <div className="grid grid-cols-1 width-full sm:grid-cols-2 gap-y-6 gap-x-4">
        <OfficeCard
          countryName="Malawi"
          flagImage={malawiFlag}
          officeLocation="Area 3, along Barron Avenue, plot 310, P.O Box 1332, Lilongwe, Malawi"
          officeNumber="+265994394970"
          email="info@pacificdiagnosticsmw.com"
        />
        <OfficeCard
          countryName="Tanzania"
          flagImage={tanzaniaFlag}
          officeLocation="First Floor, Above TTCL Customer Service Center, Opposite Science and Technology Bldg, Kijitonyama, Bagamoyo Road, Dar es Salaam, Tanzania"
          email="info@pacificafrica.com"
        />

        <OfficeCard
          countryName="Kenya"
          flagImage={kenyaFlag}
          officeLocation="302, Apricot Suites, Fourth Parklands Rd Parklands Nairobi Kenya"
          email="info.ke@pacificafrica.com"
        />

        <OfficeCard
          countryName="Uganda"
          flagImage={ugandaFlag}
          officeLocation="House of hope, Windsor Loop, plot 10 kololo, Kampala Uganda"
          email="info@pacificafrica.com"
        />
      </div>
      <h5 className="mt-2 text-2xl font-bold tracking-tight text-gray-700 dark:text-white">
            Check us Google Map
          </h5>
        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3871.343678553856!2d33.760646775094756!3d-13.99762458642034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDU5JzUxLjUiUyAzM8KwNDUnNDcuNiJF!5e0!3m2!1sen!2smw!4v1706543002475!5m2!1sen!2smw" width="600" height="450" style={{border:"0px", display:"flex", width: "100%", margin:"1rem .5rem"}} allowFullScreen={false} loading="lazy" ></iframe>
    </div>
  );
};
