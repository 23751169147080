import { MdOutlineDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";
export const ProjectCard = ({ project, selectFn }) => {
  const imageIndex = Math.floor(Math.random() * (project.images?.length || 0));
  const imageUrl = project.images?.length
    ? `https://api.pacificdiagnosticsmw.com/${project.images[imageIndex]?.url}`
    : undefined;
  // Check if project.description is defined before splitting it
  const words = project.description ? project.description.split(" ") : [];


  // Select the first 20 words
  const truncatedDescription = words.slice(0, 5).join(" ");
  return (
    <div className="relative px-3 pt-4 pb-20 bg-white rounded-lg">
      <Link to={`/about/projects/details/${project.id}`}>
        <img
          src={imageUrl}
          alt="chem-lab"
          className="h-[200px] w-full object-cover"
        />
      </Link>
      <h4 className="text-gray-800 font-semibold tracking-wider text-lg mt-3">
        {project.title }
      </h4>

      <h5 className="text-gray-800 mt-1">{project.sub_title}</h5>

      <div dangerouslySetInnerHTML={{ __html: truncatedDescription }} />
      <br />
      <div className="mt-6 text-center absolute bottom-4 left-1/2 -translate-x-1/2">
        <Link
          to={`/about/projects/details/${project.id}`}
          className="inline-block outline outline-2 outline-green-500 hover:text-green-500 px-8 text-lg py-1 rounded-full mt-4 lg:mt-8 transition-all duration-300 ease"
          onClick={() => {
            localStorage.setItem("project-id", JSON.stringify(project.id));
          }}
        >
          Read More
        </Link>
        {selectFn && (
          <div className="flex items-center justify-center gap-x-2 mt-4">
            {/* <button className="bg-blue-600 text-white flex items-center gap-x-1 font-medium dark:text-blue-500 hover:text-white hover:bg-blue-500 rounded-lg p-2">
            <MdOutlineEdit className="text-xl" />
            edit
          </button> */}
            <button
              className="bg-red-600 flex items-center gap-x-1 font-medium text-white dark:text-red-500 hover:text-white hover:bg-red-500 rounded-lg p-2"
              onClick={selectFn}
            >
              <MdOutlineDeleteOutline className="text-xl" />
              delete
            </button>
          </div>
        )}
      </div>
      <br />
    </div>
  );
};
