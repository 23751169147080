
import { BlogCard, Nodata } from "../../components/cards";
import { useFetch } from "../../hooks";
import { BlogT } from "../../types";
import { LargeSpinner } from "../../components";

export const Blogpage = () => {

  const query = useFetch<BlogT>({
    endpoint: "/blog",
    cacheId: "blog",
  });

 
  return (
    <div className="w-full bg-[#f9f9f9] py-[50px]">
      {query.isPending ? (
        <LargeSpinner />
      ) : (
        <div className="max-w-[1240px] mx-auto">
          <div>
            {query.isSuccess && (
              <div>
                {query.data.length ? (
                  <div
                    className="grid lg:grid-cols-3  md:grid-cols-3 sm:grid-cols-2 gap-8 px-4 text-black"
                  >
                    {query.data.map((blog) => (
                      <BlogCard key={blog.id} blog={blog} />
                    ))}
                  </div>
                ) : (
                  <Nodata />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
