import { useState } from "react";
import { useDeleteMutation, useFetch } from "../../hooks";
import { Nodata, ServiceCard } from "../../components/cards";
import { LargeSpinner } from "../../components";
import { ServicesT } from "../../types/ServicesT";
import { DeleteModal } from "../../components/modals";

export const AdminServices = () => {
  const query = useFetch<ServicesT>({
    endpoint: "services",
    cacheId: "services",
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedServicesId, setSelectedServicesId] = useState<number | null>(
    null
  );

  const deleteMutation = useDeleteMutation({
    endpoint: "/services",
    cacheId: "services",
  });

  const deleteServices = () => {
    deleteMutation.mutate(selectedServicesId!);
    setShowModal(false);
  };
  return (
    <div>
      {query.isPending || deleteMutation.isPending ? (
        <LargeSpinner />
      ) : (
        <div>
          {query.isSuccess && (
            <div>
              {query.data.length ? (
                <div>
                  {query.data.map((service) => (
                    <ServiceCard
                      key={service.id}
                      title={service.title}
                      description={service.description}
                      images={
                        "https://api.pacificdiagnosticsmw.com/" +
                        service.images[0].url
                      }
                      selectFn={() => {
                        setSelectedServicesId(service.id);
                        setShowModal(true);
                      }}
                    />
                  ))}
                  <DeleteModal
                    modalTitle="Delete Services"
                    showModal={showModal}
                    closeModal={() => setShowModal(false)}
                    deleteFn={deleteServices}
                  />
                </div>
              ) : (
                <Nodata />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
