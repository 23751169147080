import { LargeSpinner } from "../../components";
import { Nodata, ServiceCard } from "../../components/cards";
import { useFetch } from "../../hooks";
import { ServicesT } from "../../types/ServicesT";

export const Services = () => {
  const query = useFetch<ServicesT>({
    endpoint: "services",
    cacheId: "services",
  });
  console.log(query);

  return (
    <div className="font-bold bg-green-10 text-gray-500 dark:text-gray-400 px-4 text-center py-2">
      <h1 className="px-2 text-2xl sm:text-2xl md:text-3xl lg:text-4xl font-bold">
        SUPPLY AND <span className="text-green-400"> SERVICES</span>
      </h1>
      <div>
        <p className="text-center px-2 py-2 text-gray-500 dark:text-gray-400">
          The company's products are quite diverse, but yet focus on Healthcare
          Imaging as the primary business segment. <br />
          Pacific Diagnostics specializes in the supply and service of;
        </p>
      </div>
      {query.isPending ? (
        <LargeSpinner />
      ) : (
        <div>
          {query.isSuccess && (
            <div>
              {query.data.length ? (
                query.data.map((services) => (
                  <ServiceCard
                    key={services.id}
                    selectFn={null}
                    title={services.title}
                    description={services.description}
                    images={
                      "https://api.pacificdiagnosticsmw.com/" +
                      services.images[0].url
                    }
                  />
                ))
              ) : (
                <Nodata />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
