import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { ModalContainer } from "../ModalContainer";
import { FAQData, ModalContainerT } from "../../types";
import { useUploadMutation } from "../../hooks";
import { LargeSpinner } from "..";

export const AddFAQModal = (
  props: Pick<ModalContainerT, "showModal" | "closeModal">
) => {
  const [faqData, setFaqData] = useState<FAQData>({
    question: "",
    answer: "",
  });

  const mutation = useUploadMutation<FAQData>({
    endpoint: "/f_a_qs",
    cacheId: "faqs",
  });

  const handleUploadBlog = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    mutation.mutate({
      question: faqData.question,
      answer: faqData.answer,
    });
  };

  const faqDataChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFaqData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    if(mutation.isSuccess) {
      props.closeModal()
      location.reload();
    }
  }, [mutation, props])

  return (
    <ModalContainer
      modalTitle="Add FAQ"
      showModal={props.showModal}
      closeModal={props.closeModal}
    >
      {mutation.isPending ? (
        <LargeSpinner />
      ) : mutation.isSuccess ? (
        <h3>Blog posted</h3>
      ) : null}
      <form className="py-3 px-5" onSubmit={handleUploadBlog}>
        <div className="mb-4 sm:w-[300px] lg:w-[350px]">
          <label
            htmlFor="question"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Question
          </label>

          <input
            type="text"
            id="question"
            name="question"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
            placeholder="Question"
            value={faqData.question}
            onChange={faqDataChange}
          />
        </div>

        <div className="mb-4 sm:w-[300px] lg:w-[350px]">
          <label
            htmlFor="answer"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Answer
          </label>

          <input
            type="text"
            id="answer"
            name="answer"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
            placeholder="answer"
            value={faqData.answer}
            onChange={faqDataChange}
          />
        </div>

        <div>
          <button disabled={mutation.isPending} className="w-full bg-primary py-2 rounded text-white tracking-wider">
            Upload FAQ
          </button>
        </div>
      </form>
    </ModalContainer>
  );
};
