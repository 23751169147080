import { Laboratory10 } from "../../assets";
import { Nodata, ProductClient } from "../../components/cards";
import { useFetch } from "../../hooks";
import { ProductT } from "../../types";
import { LargeSpinner } from "../../components";

export const ProductsPage = () => {
  const query = useFetch<ProductT>({
    endpoint: "/product",
    cacheId: "products",
  });

  return (
    <div className="py-6 px-3 sm:px-16 lg:px-32 bg-green-50">
      <h3 className="capitalize text-center text-green-600 text-2xl md:text-3xl lg:text-4xl xl:text-[2.5rem] font-bold">
        Explore Our Range of Cutting-Edge Medical Products
      </h3>
      <p className="p-2">
        Welcome to Pacific Diagnostic Ltd's product page. Explore our range of
        advanced medical equipment, accessories, and consumables. From
        diagnostic tools to surgical instruments, we provide top-quality
        solutions to enhance patient care. Count on us for reliability and
        innovation, backed by comprehensive after-sales support. Elevate your
        practice with Pacific Diagnostic Ltd.
      </p>
      {query.isPending ? (
        <LargeSpinner />
      ) : (
        <div>
          {query.isSuccess && (
            <div>
              {query.data.length ? (
                <div className="w-full grid gap-y-3 gap-x-2 sm:grid-cols-2 md:grid-cols-3">
                  {query.data.map((product) => (
                    <ProductClient
                      key={product.id}
                      image={
                        `https://api.pacificdiagnosticsmw.com/${product.images[0].url}` ||
                        Laboratory10
                      }
                      availability={product.availability}
                      quantity={product.quantity}
                      title={product.title}
                      id={product.id}
                      price={product.price}
                    />
                  ))}
                </div>
              ) : (
                <Nodata />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
