import { Link } from "react-router-dom";
import { Laboratory11 } from "../assets";

export const AboutSection = () => {
  return (
    <div className="px-3 sm:px-8 lg:px-40 py-8">
      <div className="relative z-10 grid grid-cols-1 sm:grid-cols-2 items-center justify-center gap-4 lg:gap-x-8 xl:gap-x-14 py-4">
        <div className="relative">
          <div className="relative z-10">
            <img
              src={Laboratory11}
              alt="chem-lab"
              className="rounded-3xl w-full h-[280px] sm:h-[350px] object-cover"
            />
          </div>
          <div className="absolute bg-primary w-[150px] h-[150px] rounded-lg -top-2 -left-2"></div>
          <div className="absolute bg-primary w-[150px] h-[150px] rounded-lg -bottom-2 -right-2"></div>
        </div>

        <div>
          <h3 className="capitalize text-green-600 text-2xl md:text-3xl lg:text-4xl xl:text-[2.5rem] font-bold">
            about us
          </h3>

          <div className="mt-5 text-gray-500 tracking-wide w-[94%]">
            <p>
              Pacific Diagnostic Ltd is a distributor of medical equipment,
              accessories and consumables in East Africa. Today is a growing
              leader in the distribution of medical equipment and consumables
              within the region, specialzing in mdecial imaging and surgery.
            </p>

            <p className="mt-3">
              We have a good installed base of medical equipment in East Africa
              which is suppoirteed by an experienced factory trained service
              department.
            </p>

            <Link
              to={"about/company"}
              className="block outline outline-2 outline-green-500 w-fit px-8 lg:px-10 text-lg py-2 rounded-full hover:text-green-600 mt-4 lg:mt-8 transition-all duration-300 ease"
            >
              Learn More
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

