import { useState } from "react";
import { ProjectStatus, ProjectT } from "../../types";
import { ProjectCard } from "../../components/cards";
import { useFetch } from "../../hooks";
import { LargeSpinner } from "../../components";

export const AboutProjects = () => {
  const [projectStatus, setProjectStatus] =
    useState<ProjectStatus>("complete");
  const query = useFetch<ProjectT>({
    endpoint: "/projects",
    cacheId: "projects",
  });
console.log(query);

  return (
    <div className="px-3 sm:px-16 lg:px-32 bg-green-50 py-6 lg:py-14">
      {query.isPending ? (
        <LargeSpinner />
      ) : (
        <div>
          <div className="flex items-center lg:justify-center gap-x-3 lg:gap-x-4 pb-3 pt-5 md:pt-20 lg:pt-5 overflow-x-auto hide-scroll px-3">
            <button
              className={`${
                projectStatus === "complete"
                  ? "bg-primary text-white"
                  : "text-primary"
              } inline-block whitespace-nowrap px-5 py-2 border-2 border-primary rounded-3xl hover:bg-primary hover:text-white transition-all duration-200 ease`}
              onClick={() => setProjectStatus("complete")}
            >
              Completed
            </button>

            <button
              className={`${
                projectStatus === "in progress"
                  ? "bg-primary text-white"
                  : "text-primary"
              } inline-block whitespace-nowrap px-5 py-2 border-2 border-primary rounded-3xl hover:bg-primary hover:text-white transition-all duration-200 ease`}
              onClick={() => setProjectStatus("in progress")}
            >
              In Progress
            </button>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-6 gap-x-4">
            {query.isSuccess &&
              query.data
                .filter(
                  (project) => project.status.toLowerCase() === projectStatus
                )
                .map((project) => (
                  <ProjectCard selectFn={null} key={project.id} project={project} />
                ))}
          </div>
        </div>
      )}
    </div>
  );
};
