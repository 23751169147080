import { FC } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { TestimonialT } from "../../types";

export const TestimonialCard: FC<{
  testmonial: TestimonialT;
  selectFn?(): void;
  targetUser: "client" | "admin";
}> = ({ testmonial, selectFn, targetUser }) => {
  console.log(testmonial);
  return (
    <div className="pt-4 mb-8">
      <div
        className={`max-w-screen-md mx-auto text-center ${
          targetUser === "client" ? "py-20 bg-white rounded block" : ""
        }`}
      >
        <svg
          className="w-10 h-10 mx-auto mb-3 text-gray-400 dark:text-gray-600"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 18 14"
        >
          <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
        </svg>
        <blockquote>
          <p className="text italic font-medium text-gray-900 dark:text-white ">
            {testmonial.content}
          </p>
        </blockquote>
        <figcaption className="flex items-center justify-center mt-6 space-x-3">
          <img
            className="w-6 h-6 rounded-full"
            src={
              "https://api.pacificdiagnosticsmw.com/" +
              testmonial.images[0]?.url
            }
            alt="profile picture"
          />
          <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
            <h3 className="pr-3 font-medium text-gray-900 dark:text-white">
              {testmonial.fullName}
            </h3>
            <h4 className="pl-3 text-sm text-gray-500 dark:text-gray-400">
              {testmonial.position}
            </h4>
          </div>
        </figcaption>
      </div>

      <div
        className={`${
          targetUser === "client" ? "hidden" : "flex"
        }  items-center justify-center gap-x-2 mt-7`}
      >
        {/* <button className=" bg-blue-600 text-white flex items-center gap-x-1 font-medium dark:text-blue-500 hover:text-white hover:bg-blue-500 rounded-lg p-2">
          <MdOutlineEdit className="text-xl" />
          edit
        </button> */}
        <button
          className=" bg-red-600 flex items-center gap-x-1 font-medium text-white dark:text-red-500 hover:text-white hover:bg-red-500 rounded-lg p-2"
          onClick={selectFn}
        >
          <MdOutlineDeleteOutline className="text-xl" />
          delete
        </button>
      </div>
    </div>
  );
};
