import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { ModalContainer } from "../ModalContainer";
import { ModalContainerT, ProductData } from "../../types";
import { useUploadMutation } from "../../hooks";
import { LargeSpinner } from "..";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

interface QuillEditorProps {
  onDescriptionChange: (value: string) => void;
}

class QuillEditor extends React.Component<QuillEditorProps, { text: string }> {
  modules: { toolbar: (string[] | { list: string }[])[] };
  formats: string[];
  constructor(props: QuillEditorProps) {
    super(props);
    this.modules = {
      toolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
        ["clean"],
      ],
    };
    this.formats = [
      "bold",
      "italic",
      "underline",
      "strike",
      "list",
      "bullet",
      "link",
    ];
    this.state = { text: "" };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value: string) {
    this.setState({ text: value });
    this.props.onDescriptionChange(value); // Pass the description value to the parent component
  }
  render() {
    return (
      <ReactQuill
        value={this.state.text}
        onChange={this.handleChange}
        modules={this.modules}
        formats={this.formats}
      />
    );
  }
}

export const AddProductModal = (
  props: Pick<ModalContainerT, "showModal" | "closeModal">
) => {
  const [productData, setProductData] = useState<ProductData>({
    title: "",
    description: "",
    price: 0,
    quantity: 0,
    availability: "",
    images: null,
  });

  const mutation = useUploadMutation<ProductData>({
    endpoint: "/product",
    cacheId: "products",
  });

  const handleUploadBlog = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    mutation.mutate({
      title: productData.title,
      description: productData.description,
      price: productData.price,
      quantity: productData.quantity,
      availability: productData.availability,
      images: productData.images,
    });
  };

  const productDataChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    setProductData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDescriptionChange = (value: string) => {
    setProductData((prevData) => ({ ...prevData, description: value }));
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      props.closeModal();
      location.reload();
    }
  }, [mutation, props]);

  return (
    <ModalContainer
      modalTitle="Add Product"
      showModal={props.showModal}
      closeModal={props.closeModal}
    >
      {mutation.isPending ? (
        <LargeSpinner />
      ) : mutation.isSuccess ? (
        <h3>Product added</h3>
      ) : null}
      <form style={{width:"1200px"}} className="py-3 px-5" onSubmit={handleUploadBlog}>
        <div className="mb-4 w-full">
          <label
            htmlFor="title"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Title
          </label>

          <input
            type="text"
            id="title"
            name="title"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
            placeholder="Title"
            value={productData.title}
            onChange={productDataChange}
          />
        </div>

        <div className="mb-4 w-full">
          <label
            htmlFor="description"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Description
          </label>

          <QuillEditor onDescriptionChange={handleDescriptionChange} />
        </div>

        <div className="flex flex-col sm:flex-row items-center gap-3">
          <div className="mb-4 w-full sm:w-[145px] lg:w-[170px]">
            <label
              htmlFor="quantity"
              className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
            >
              Quantity
            </label>

            <input
              type="number"
              id="quantity"
              name="quantity"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
              placeholder="Quantity"
              value={productData.quantity}
              onChange={productDataChange}
            />
          </div>

          <div className="mb-4 w-full sm:w-[145px] lg:w-[170px]">
            <label
              htmlFor="price"
              className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
            >
              Price
            </label>

            <input
              type="number"
              id="price"
              name="price"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
              placeholder="Price"
              value={productData.price}
              onChange={productDataChange}
            />
          </div>
        </div>

        <div className="mb-4 w-full">
          <label
            htmlFor="availability"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Availability
          </label>

          <select
            id="availability"
            name="availability"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-primary"
            onChange={productDataChange}
            value={productData.availability || "default"}
          >
            <option value={"default"} disabled>
              __select__
            </option>
            <option value="available">Available</option>
            <option value="out-out-stock">Out of stock</option>
          </select>
        </div>

        <div className="mb-4 w-full">
          <label
            htmlFor="images"
            className="block mb-1 text-sm text-gray-700 dark:text-white font-medium"
          >
            Images
          </label>

          <input
            type="file"
            id="images"
            className="bg-gray-50 text-sm rounded block w-full"
            multiple
            accept="image/*"
            onChange={(event) =>
              setProductData((prevData) => ({
                ...prevData,
                images: event.target.files,
              }))
            }
          />
        </div>

        <div>
          <button className="w-full bg-primary py-2 rounded text-white tracking-wider">
            Upload Product
          </button>
        </div>
      </form>
    </ModalContainer>
  );
};
