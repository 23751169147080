import { team1_kaunda, team3_urjit} from "../../assets";
import TeamDetails from "../../components/cards/TeamDetails";
export const TeanInfo = () => {
  return (
    <div className="py-6 px-3 sm:px-16 lg:px-32 bg-green-50">
      <h3 className="capitalize text-center text-green-600 text-2xl md:text-3xl lg:text-4xl xl:text-[2.5rem] font-bold">
        Meet our team
      </h3>

      <div className="w-full grid gap-y-3 sm:grid-cols-2 md:grid-cols-3 sm:gap-x-3 lg:grid-cols-4 lg:gap-x-5 px-[1.5rem] sm:px-6 py-8">
        <TeamDetails
          name="Khumbo Kaunda"
          position="Country Director"
          image={team1_kaunda}
          description="MBA (Finance). Registered professional Accountant with Institute of Chartered Accountant in Malawi as well as Malawi Accountants Board. (ICAM 2291). More than ten years’ experience in Financial and Administrative matters. Done Auditing and consultancy work in Tanzania and Malawi."
          phoneNumber="+265994394970"
          email="info@pacificdiagnosticsmw.com"
        />
        <TeamDetails
          name=" Urjit Thakur"
          position="Customer Service Engineer"
          image={team3_urjit}
          description="Siemens trained Biomedical Engineer with 10 years experience, in installation and maintenance of hospital equipment"
          phoneNumber=" +265880917288"
          email="urjit.thakur@pacificdiagnosticsmw.com"
        />
        {/* <TeamDetails
          name=" Prashant Gokarn"
          position="Managing Director"
          image={team2}
          description="With over 20 years’ experience: Trained by Siemens, Germany in X-ray systems, Ultrasound scanners, Basic MRI, Basic CT, Patient Monitors, Defibrillators, ECG and EEG systems, Dental equipment etc."
          phoneNumber="+255784220065"
          email=""
        /> */}
        {/* <TeamDetails
          name=" Ian kasitomu"
          position="Marketing Executive"
          image={team_ian}
          description="B. COm(FIA)(In), Dip Rad(Mw). Over 12 years’ experience having worked in the banking sector and the Ministry of Health, Malawi in Medical Imaging Services. An accomplished, versatile and results-driven professional with a proven track record in driving sales, increasing revenue, and providing superior customer service, ultimately benefiting the company bottom line."
          phoneNumber=""
          email="ian.kasitomu@pacificdiagnosticsmw.com"
        /> */}
      </div>
    </div>
  );
};
